<div id="requestTrackerDetailModal" class="flex flex-col w-full h-full">
  <header>
    <h2 class="m-10 mr-20 text-lg font-bold">{{data.category}} {{data.subCategory !== null ? ' - ' + data.subCategory :
    ''}} Request Details</h2>
    <button mat-button class="m-10 ml-auto icon-button is-primary is-inverted" tabindex="-1" type="button"
    mat-dialog-close>
      <i class="material-icons">close</i>
    </button>
  </header>
  <section class="flex flex-col overflow-auto p-30 gap-y-20">
    @if (data.category !== requestTrackerTypeEnum.PlanningDesk) {
      <div class="flex flex-col gap-y-5">
        <h2 class="font-bold text-gray-dark" [textContent]="data.investmentAccountModel || 'No Model'"></h2>
        <span class="text-gray">{{data.custodian}}{{data.investmentAccountNumber ? ' - ' + data.investmentAccountNumber :
        ''}}</span>
      </div>
    }
    @if (statusObj.Status) {
      <div class="flex flex-col gap-y-5">
        <div class="flex justify-between border-dotted border-gray-light">
          <span class="text-gray-dark">Status:</span>
          <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
          <div class="font-bold text-gray-dark flex-row">
          <span [ngStyle]="{'color': 
            statusObj.StatusId === 4 ? '#D71D00' : 
            statusObj.StatusId === 5 ? '#127161' : 
            statusObj.StatusId === 6 ? '#FFAF3D' : 
            statusObj.StatusId === 7 ? '#D71D00' : 
            statusObj.StatusId === 8 ? '#D71D00' : 
            statusObj.StatusId === 9 ? '#D71D00' : 
            ''}"> {{ statusObj.Status }}</span> - <span>{{ statusObj.StatusDate }}
          </span>
          </div>
        </div>
        @if (statusObj.StatusNote) {
          <div class="flex flex-col p-5 mt-5 rounded bg-gray-lightest">
            <span class="whitespace-pre-wrap overflow-wrap-text text-gray-dark text-sm">{{ statusObj.StatusNote }}</span>
          </div>
        }
        <div class="flex justify-center mt-10">
          @if (statusObj.StatusId !== 5) {
            <button matTooltip="Send a new message with files" type=""
              class="is-primary is-outlined bg-blue-lightest is-wide is-inverted relative"
              (click)="closeDialogAndSendMessage();">
              <span class="text-xs text-blue-dark"> Send New Message</span>
            </button>
          }
        </div>
      </div>
    }
    <span class="flex-none divider-horizontal"></span>
    <div class="flex flex-col gap-y-10">
      <span class="text-base font-bold">Request Notes:</span>
      @if (note.length) {
        <div class="flex flex-col p-5 rounded bg-gray-lightest">
          <span class="whitespace-pre-wrap overflow-wrap-text text-gray-dark text-sm">{{note}}</span>
        </div>
      } @else {
        <em class="text-sm text-gray-light">No notes</em>
      }
    </div>
    @if (files.length) {
      <div>
        <h2 class="mb-10 text-base font-bold text-gray-dark">File Attachments</h2>
        <div class="grid grid-cols-1 gap-10 sm:grid-cols-2">
          @for (asset of files; track asset) {
            <span
              class="flex items-center w-full transition duration-150 rounded cursor-pointer text-blue-dark hover:bg-blue-lightest">
              <a class="flex items-center w-full no-underline rounded cursor-pointer text-blue-dark hover:no-underline focus:no-underline"
                [href]="filePrefix + asset.fileID" target="_blank" [attr.aria-label]="'Open ' + asset.fileName">
                <div class="flex items-center justify-center flex-shrink-0 w-48 h-48">
                  <mat-icon class="mi-18 text-blue-dark">description</mat-icon>
                </div>
                <span class="text-sm font-bold leading-normal">{{asset.fileName}}</span>
              </a>
            </span>
          }
        </div>
      </div>
    }
    <div class="flex flex-col">
      @for (detail of details | keyvalue: sortNull; track detail) {
        <div class="flex-col py-2">
          <div class="flex justify-between border-dotted border-gray-light">
            <div class=" text-gray-dark"
              [ngClass]="{'font-bold mt-10': detail.key === sleevesTitle || detail.key === accountsTitle  || detail.key === tradesTitle}">
            {{detail.key}}</div>
            @if (detail.key !== sleevesTitle && detail.key !== accountsTitle && detail.key !== tradesTitle) {
              <div class="flex-grow mx-10 border-b border-dotted border-gray-light"
              ></div>
            }
            @if (detail.key !== clientTitle && detail.key !== sleevesTitle && detail.key !== accountsTitle  && detail.key !== tradesTitle) {
              <div class="font-bold text-gray-dark"
                >
              {{detail.value}} </div>
            }
            @if (detail.key === grossContributionAmountTitle) {
              <div class="font-bold text-gray-dark">{{grossContributionAmount |
              currency}}</div>
            }
            @if (detail.key === grossDistributionAmountTitle) {
              <div class="font-bold text-gray-dark">{{grossDistributionAmount |
              currency}}</div>
            }
            @for (client of clients; track client; let lastClient = $last) {
              <div class="font-bold text-gray-dark">
                @if (detail.key === clientTitle) {
                  <span>{{client.firstName}} {{client.lastName}}@if (!lastClient) {
                    <span
                    >,&nbsp;</span>
                  }</span>
                }
              </div>
            }
          </div>
          @if (detail.key === accountsTitle) {
            <div class="flex flex-col w-full">
              @for (account of planningDeskAccounts; track account) {
                <div class="flex-col py-2 ml-20">
                  <div class="flex justify-between border-dotted border-gray-light">
                    <span class="text-gray-dark">Account Type</span>
                    <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                    <span class="font-bold text-gray-dark">{{account.investmentAccountType}} </span>
                  </div>
                  <div class="flex justify-between border-dotted ml-15 border-gray-light">
                    <span class="text-gray-dark">Current Account Value</span>
                    <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                    <span class="font-bold text-gray-dark">{{account.currentAccountValue | currency}} </span>
                  </div>
                  <div class="flex justify-between border-dotted ml-15 border-gray-light">
                    <span class="text-gray-dark">Client Fee</span>
                    <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                    <span class="font-bold text-gray-dark">{{account.clientFee | percent: '1.0-3'}} </span>
                  </div>
                </div>
              }
            </div>
          }
          @if (detail.key === sleevesTitle) {
            <div class="flex flex-col w-full">
              @for (sleeve of sleeves; track sleeve) {
                <div class="flex-col py-2 ml-20 pt-5">
                  <div class="flex justify-between border-dotted border-gray-light">
                    <span class=" text-gray-dark">Account Model</span>
                    <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                    <span class="font-bold text-gray-dark">{{sleeve.investmentAccountModel}} </span>
                  </div>
                  <div class="flex justify-between border-dotted ml-15 border-gray-light">
                    <span class=" text-gray-dark">Amount</span>
                    <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                    <span class="font-bold text-gray-dark">{{sleeve.amount | currency}}</span>
                  </div>
                  @if (data.category === requestTrackerTypeEnum.Reallocation) {
                    <div class="flex justify-between border-dotted ml-15 border-gray-light"
                      >
                      <span class=" text-gray-dark">Total Account Fee</span>
                      <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                      <span class="font-bold text-gray-dark">{{sleeve.accountTotalFee | percent: '1.0-3'}} </span>
                    </div>
                  }
                  <div class="flex justify-between border-dotted ml-15 border-gray-light">
                    <span class=" text-gray-dark">Percentage</span>
                    <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                    <span class="font-bold text-gray-dark">{{(sleeve.amount / grossSleeveAmount) | percent: '1.2'}}</span>
                  </div>
                </div>
              }
            </div>
          }
          @if (detail.key === tradesTitle) {
            <div class="flex flex-col w-full">
              @if (tradeInstructions.length) {
                <div class="flex flex-col py-5 gap-y-10">
                  <div class="flex flex-col p-5 rounded bg-gray-lightest">
                    <span class="whitespace-pre-wrap overflow-wrap-text text-gray-dark text-sm">{{tradeInstructions}}</span>
                  </div>
                </div>
              }
              @for (trade of trades; track trade) {
                <div class="flex-col py-2 ml-20 pt-5">
                  <div class="flex justify-between border-dotted border-gray-light">
                    <span class=" text-gray-dark">Instruction</span>
                    <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                    <span class="font-bold text-gray-dark">{{trade.instruction}}</span>
                  </div>
                  @if (trade.instruction.toLowerCase().indexOf('all') === -1) {
                    <div class="flex justify-between border-dotted ml-15 border-gray-light"
                      >
                      <span class=" text-gray-dark">Amount</span>
                      <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                      <span class="font-bold text-gray-dark">{{ trade.instruction.toLowerCase().indexOf('dollars') !== -1 ?
                        (trade.amount | currency) : trade.amount
                      }} </span>
                    </div>
                  }
                  <div class="flex justify-between border-dotted ml-15 border-gray-light">
                    <span class=" text-gray-dark">Symbol</span>
                    <div class="flex-grow mx-10 border-b border-dotted border-gray-light"></div>
                    <span class="font-bold text-gray-dark">{{trade.symbol}}</span>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>
  </section>
</div>