@for (allocation of customHoldingAllocations?.controls; track allocation; let i = $index) {
  <div class="flex flex-row justify-between gap-x-10 text-sm">
    @if (!allocation.value.action.isStock) {
      <span>{{allocation.value.action.name}} </span>
    }
    <span>{{allocation.value.stockSymbol}}</span>
    <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
    <span [ngClass]="{'text-green' : allocation.value.type === Buy, 'text-orange': allocation.value.type === Sell}">
      {{allocation.value.action.tradeType}}
      {{allocation.value.action.allocationAmountType === Share ? allocation.value.amount : (allocation.value.amount | currency) }}
      @if (allocation.value.action.allocationAmountType === Share) {
        <span> Shares</span>
      }
    </span>
  </div>
}