<nav routerLinkActive="is-active" class="acm-portal-subnav subnav-absolute">
  @for (item of subNavigationItems; track item) {
    <a class="pl-0 acm-portal-nav-item" [routerLink]="'/' + item.navigation?.absolutePath" routerLinkActive="is-active">
      <span class="relative flex flex-col items-center justify-center w-64 h-48" [matTooltip]="item.navigation!.name"
        matTooltipPosition="right">
        @if (item.navigation!.icon) {
          <mat-icon class="mi-18" [svgIcon]="item.navigation!.icon"></mat-icon>
        }
        @if (item.navigation!.icon) {
          <i class="fig-18px {{item.navigation!.name}}"></i>
        }
      </span>
      @if (item.navigation) {
        <span class="acm-portal-nav-label">{{item.navigation.name}}</span>
      }
    </a>
  }
</nav>