<section class="flex w-full h-full p-30">
  <div class="flex flex-col w-full mx-auto max-w-1440 gap-y-30">
    <a routerLink="../overview" class="p-5 text-sm font-bold uppercase flex items-center gap-x-5 mr-auto">
      <mat-icon svgIcon="arrow-left" class="mi-18"></mat-icon>
      <span>Back</span>
    </a>
    <section class="flex flex-col gap-y-30">
      <div class="flex gap-x-30">
        <div class="flex flex-col gap-y-10 max-w-640">
          <h2 class="text-xl font-bold">FinishSTRONG&trade;</h2>
          <!-- TODO change this placeholder text -->
          <p class="text-lg">
            As we look to continue the momentum of the year, it’s time to come together and FINISH <b>STRONG!</b> Each year’s virtual learning summit is designed to elevate skills and knowledge to help you FINISH the year <b>STRONG</b> and position you well for the following year and beyond.
          </p>
        </div>
      </div>
      @for (model of wistiaSections; track model; let index = $index) {
        <section class="card px-30">
          <app-development-center-wistia-section class="flex flex-col" [model]="model" [wistiaProject]="wistiaProject"></app-development-center-wistia-section>
        </section>
      }
    </section>
  </div>
</section>