import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from 'src/app/core/auth/permissions.service';
import { CustomRoute } from 'src/app/shared/models/custom-route.model';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuardService   {

  constructor(private permissionService:PermissionsService, private router: Router) {}

  canActivate(requestedRoute: ActivatedRouteSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // return true;
    let route: ActivatedRouteSnapshot|null = requestedRoute;

    // loop through parent routes to make sure they have the proper permissions
    let canAccess = true;
    do {
      const permissions = (route.routeConfig as CustomRoute).permissions;
      canAccess = permissions ? this.permissionService.hasOnePermission(permissions) : true;
      route = route.parent;
    } while (canAccess && route && route.routeConfig);

    if (!canAccess) {
      return this.router.parseUrl('/portal');
    }
    return canAccess;
  }
}
