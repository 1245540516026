<div class="relative flex flex-col items-center justify-center p-20 text-center transition duration-150 border-2 border-dashed rounded cursor-pointer border-gray-lighter min-h-100 hover:text-blue hover:border-blue text-gray"
  [class]="dragover? 'border-blue text-blue':''">
  <input #fileUpload class="absolute top-0 left-0 w-full h-full opacity-0" type="file" multiple name="upload"
    [accept]=fileExtensions (dragover)="dragOverHandler($event)" (change)="uploadFiles($event)">
  <label class="text-sm font-semibold">Drag or click here to upload</label>
  <label class="text-sm font-semibold">Accepts {{fileExtensionMessage}}</label>
</div>
@if (showUploadErrorMessage) {
  <div
    class="p-10 mt-10 border border-solid rounded border-red-light bg-red-lightest text-red">
    {{uploadErrorMessage}}
  </div>
}
@if (files.length) {
  <div class="flex flex-wrap flex-none mt-10">
    @for (file of files; track file) {
      <div class="flex items-center w-full px-10 py-5">
        <p class="mr-10">{{file.name}}</p>
        <button class="ml-auto icon-button is-warning is-inverted is-sm" aria-label="Delete file button" type="button"
          (click)="removeFile(file)">
          <span class="material-icons mi-18">close</span>
        </button>
      </div>
    }
  </div>
}