@if (parentForm && parentForm.valid) {
  <section class="flex flex-col gap-y-30">
    <p class="text-lg">Please review your request below and make sure all the information is accurate.</p>
    <div class="flex flex-col gap-y-10">
      <div class="flex flex-col gap-y-10">
        <span>Reason:</span>
        @if (reviewModel?.notes?.length) {
          <div class="flex flex-col p-10 rounded md:p-20 bg-gray-lightest">
            <span class="mb-10 whitespace-pre-wrap overflow-wrap-text text-gray-dark">{{reviewModel?.notes}}</span>
          </div>
        }
      </div>
      <span class="divider-horizontal my-20"></span>
      <app-review-row-entry [dataName]="'Bill Account On Closing'"
      [dataValue]="reviewModel?.billAccountOnClosing ? 'Yes' : 'No'"></app-review-row-entry>
      <app-review-row-entry [dataName]="'Federal Tax'"
      [dataValue]="(reviewModel?.taxWithholding?.federalPercent | number : '1.2-2') + ' %'"></app-review-row-entry>
      <app-review-row-entry [dataName]="'State Tax'"
      [dataValue]="(reviewModel?.taxWithholding?.statePercent | number : '1.2-2') + ' %'"></app-review-row-entry>
      <app-review-row-entry [dataName]="'Delivery Method'"
      [dataValue]="reviewModel?.deliveryMethod"></app-review-row-entry>
    </div>
    <div class="flex flex-col">
      <span>Files:</span>
      @for (item of reviewModel?.files; track item) {
        <div class="flex flex-col gap-y-5" class="font-bold">
          {{item.name}}
        </div>
      }
    </div>
  </section>
}