@if (account && workflow) {
  <form @fadeIn [formGroup]="closeAccountFormGroup" (ngSubmit)="onSubmit()" class="flex flex-col w-full h-full overflow-hidden">
    <app-account-request-modal-header #header [title]="workflow.title" [account]="account" (closeRequested)="closeRequest()"></app-account-request-modal-header>
    @if (isSubmitting) {
      <div class="flex-grow w-full loader-wrap" @fadeIn>
        <div class="loader-sm is-blue"></div>
      </div>
    }
    @if (!isSubmitting && !isSubmitted) {
      <section class="flex flex-col flex-grow h-full overflow-auto">
        @if (currentStep === accountUpdateStepEnum.step1) {
          <div class="flex flex-col w-full">
            <div class="flex flex-col overflow-auto p-30 gap-y-30">
              <div class="flex flex-col gap-y-30" [formGroup]="closeAccountFormGroup">
                <div class="flex flex-col gap-y-10">
                  <label class="text-base font-bold text-gray-dark required-label">Reason for closing account / additional notes</label>
                  <textarea class="textarea" rows="2" placeholder="Please enter a reason for closing your account and any additional notes" formControlName="reason"></textarea>
                  @if (closeAccountFormGroup.controls.reason.touched && closeAccountFormGroup.controls.reason.errors) {
                    <span class="w-full text-xs text-red">
                      @if (closeAccountFormGroup.controls.reason.hasError('required')) {
                        <span>This field is required.</span>
                      }
                    </span>
                  }
                </div>
                <div class="flex flex-col gap-y-10">
                  <label class="text-base font-bold text-gray-dark required-label -mb-5" for="billAccountOnClosing">Should the account be billed upon closing?</label>
                  <mat-checkbox class="pl-5 checkbox" formControlName="billAccountOnClosing">
                    <span class="pl-5">Bill account as of close date</span>
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <app-tax-options class="pl-30 pr-30" [taxFormGroup]="closeAccountFormGroup"></app-tax-options>
            <app-delivery-methods [parentForm]="closeAccountFormGroup.controls.deliveryOptions" [requestType]="requestTypeEnum.closeAccount"></app-delivery-methods>
          </div>
        }
        @if (currentStep === accountUpdateStepEnum.step2) {
          <div class="flex flex-col w-full">
            <div class="flex flex-col overflow-auto p-30 gap-y-30">
              <app-close-account-review-step [parentForm]="closeAccountFormGroup"></app-close-account-review-step>
            </div>
          </div>
        }
      </section>
    }
    @if (!isSubmitting && isSubmitted) {
      <section class="flex flex-col flex-grow h-full overflow-auto">
        <app-complete [complete]="isComplete" [requestType]="requestTypeEnum.closeAccount"></app-complete>
      </section>
    }
    @if (!isComplete) {
      <footer>
        @if (!isSubmitted && currentStep === accountUpdateStepEnum.step1) {
          <button [disabled]="isSubmitting" type="button" mat-button class="flex justify-center m-10 button is-primary is-inverted is-lg is-outlined"
          (click)="cancelRequest()">Back</button>
        }
        @if (!isSubmitted && (currentStep > accountUpdateStepEnum.step1)) {
          <button [disabled]="isSubmitting" type="button" mat-button class="flex justify-center m-10 button is-primary is-inverted is-lg is-outlined"
          (click)="previousStep();">Back</button>
        }
        @if (!isSubmitted && (currentStep < pages.length)) {
          <button [disabled]="isSubmitting" type="button" mat-button class="flex justify-center m-10 ml-auto button is-primary is-lg"
            (click)="nextStep(pages[currentStep - 1].formGroup)"
          >Next</button>
        }
        @if (!isSubmitted && (currentStep === pages.length)) {
          <button [disabled]="isSubmitting" type="submit" mat-button class="flex justify-center m-10 ml-auto button is-primary is-lg">
          {{isSubmitting ? "Submitting" : "Submit"}}</button>
        }
      </footer>
    }
  </form>
}