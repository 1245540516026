@if (showNotifications) {
  <section class="bg bg-no-repeat bg-optout-banner bg-cover px-30 py-10 flex flex-col bg-right">
    @if (webinar0906.Show) {
      <div class="mx-auto h-full w-full flex justify-between items-center">
        <p class="font-semibold text-lg" style="font-family: Arial, Helvetica, sans-serif;"><span class="text-white">Please join us for an upcoming webinar regarding the TD/Schwab Merger details on </span><a href="https://attendee.gotowebinar.com/register/2597884725874447450" target="_blank" class="text-yellow-light underline">Wednesday, September 6th at 9:30 AM EST</a></p>
        <div>
          <button class="mr-20 bg-yellow-light text-blue-dark animate-pulse" onClick="window.open('https://attendee.gotowebinar.com/register/2597884725874447450')">
            <span class="flex items-center justify-center">
              <span class="ml-5 font-bold text-sm">Register Now!</span>
            </span>
          </button>
        </div>
      </div>
    }
    @if (webinar0907.Show) {
      <div class="mx-auto h-full w-full flex justify-between items-center">
        <p class="font-semibold text-lg" style="font-family: Arial, Helvetica, sans-serif;"><span class="text-white">Please join us for an upcoming webinar regarding the TD/Schwab Merger details on </span><a href="https://attendee.gotowebinar.com/register/4558463492216938334" target="_blank" class="text-yellow-light underline">Thursday, September 7th at 1:00 PM EST</a></p>
        <div>
          <button class="mr-20 bg-yellow-light text-blue-dark animate-pulse" onClick="window.open('https://attendee.gotowebinar.com/register/4558463492216938334')">
            <span class="flex items-center justify-center">
              <span class="ml-5 font-bold text-sm">Register Now!</span>
            </span>
          </button>
        </div>
      </div>
    }
  </section>
}