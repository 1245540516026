<form id="openSsoModal" [formGroup]="selectedAgentFormGroup" (ngSubmit)="redirectToSso()"
  class="flex flex-col w-full h-full">
  <header>
    <h2 class="p-10 sm:p-20 mr-20 text-lg font-bold">Please select the primary advisor</h2>
    <button mat-button class="m-10 ml-auto icon-button is-primary is-inverted" tabindex="-1" type="button"
      mat-dialog-close>
      <i class="material-icons">close</i>
    </button>
  </header>
  <section class="dialog-content">
    @if (!isLoading) {
      <div class="flex flex-col mx-10 my-auto">
        <div class="flex flex-none">
          <input type="text" matInput class="w-full input text-sm" placeholder="Select an Advisor" (keydown)="resetSelectedAgent($event)" onfocus="this.select()"
            formControlName="agent" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayModel" panelWidth="min-w-250"
            (optionSelected)="onChangeModelSearch($event)"  ngDefaultControl>
            @for (a of filteredAgents | async; track a) {
              <mat-option [value]="a" class="text-sm">
                {{a.firstName + ' ' + a.lastName}}
              </mat-option>
            }
          </mat-autocomplete>
        </div>
      </div>
    } @else {
      <section class="flex flex-col flex-grow h-full">
        <div class="relative flex-grow p-40 m-auto loader-wrap">
          <div class="loader-sm is-blue"></div>
        </div>
      </section>
    }
  </section>
  <footer class="">
    <button class="fpt-8 ml-auto button is-primary" mat-button
      [disabled]="isLoading || !this.selectedAgentFormGroup.valid || !this.selectedAgentID" type="submit" matTooltip="Launch">
      Launch
    </button>
  </footer>
</form>