import { Injectable } from '@angular/core';
import { PermissionsService } from 'src/app/core/auth/permissions.service';
import { RequestTypeEnum } from '../enums/account-update.enum';
import { AccountWorkflowNavModel, SolicitationOptionModel } from '../models/workflow.models';
import { Permission } from 'src/app/core/clients/generated/client';

@Injectable({
  providedIn: 'root'
})
export class WorkflowNavigationService {

  constructor(private _permissionService: PermissionsService) { }

  get workflowNavList(): AccountWorkflowNavModel[] {
    return this._workflowNavList.filter(r => r.resolve ? r.resolve() : true);
  }

  get workflowSolicitationOptionsList(): SolicitationOptionModel[] {
    return this._workflowSilicationOptionsList;
  }

  _workflowNavList: AccountWorkflowNavModel[] = [
    {
      order: RequestTypeEnum.contribution,
      // eslint-disable-next-line quotes
      title: 'Contribute Funds',
      icon: 'attach_money',
      buttonClass: 'is-primary',
      resolve: (): boolean => {
        return this._permissionService.hasOnePermission(Permission.SubmitContributionRequest) && !this._permissionService.hasOnePermission(Permission.ViewOrionElementRequests);
      }
    },
    {
      order: RequestTypeEnum.distribution,
      // eslint-disable-next-line quotes
      title: 'Distribute Funds',
      icon: 'payments',
      buttonClass: 'is-primary',
      resolve: (): boolean => {
        return this._permissionService.hasOnePermission(Permission.SubmitDistributionRequest) && !this._permissionService.hasOnePermission(Permission.ViewOrionElementRequests);
      }
    },
    {
      order: RequestTypeEnum.reallocation,
      // eslint-disable-next-line quotes
      title: 'Model Allocation / Trade Request',
      icon: 'device_hub',
      iconTransform: 'rotate(-90deg)',
      buttonClass: 'is-primary',
      resolve: (): boolean => {
        return this._permissionService.hasOnePermission(Permission.SubmitReallocationRequest);
      }
    },
    {
      order: RequestTypeEnum.closeAccount,
      // eslint-disable-next-line quotes
      title: 'Close the Account',
      icon: 'not_interested',
      //buttonClass: 'is-warning',
      buttonClass: 'is-primary',
      resolve: (): boolean => {
        return this._permissionService.hasOnePermission(Permission.SubmitAccountCloseRequest) && !this._permissionService.hasOnePermission(Permission.ViewOrionElementRequests);
      }
    },
  ];

  _workflowSilicationOptionsList: SolicitationOptionModel[] = [
    {
      // eslint-disable-next-line quotes
      title: `IAR initiated transaction request (Solicited)`,
      isSolicited: true,
      icon: 'account_circle',
      buttonClass: 'is-primary',
    },
    {
      // eslint-disable-next-line quotes
      title: `Client initiated transaction request (Unsolicited)`,
      isSolicited: false,
      icon: 'phone_callback',
      buttonClass: 'is-primary',
    }
  ];
}