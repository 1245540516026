<header>
  <div class="m-10 flex flex-col gap-y-5">
    <h2 class="text-lg font-bold mr-20 leading-tight">{{title}}
      @if (formService) {
        <span> - Step {{formService.currentStep}}/{{formService.pages.length}}</span>
      }
    </h2>
    <span class="text-gray leading-tight capitalize">{{account?.custodian + ' - ' + (account?.accountNumber ? account?.accountNumber + ' - ': '') + clients}}</span>
  </div>
  <button mat-button class="m-10 ml-auto icon-button is-primary is-inverted" type="button" (click)="closeModal()">
    <i class="material-icons">close</i>
  </button>
</header>