import { Component, Input } from '@angular/core';
import { ContributionFundsFormGroup } from '../../models/contribute-funds-form-group';
import { NgxCurrencyConfig } from 'ngx-currency';
import { CustomCurrencyMaskConfig } from 'src/app/shared/config/currency-mask.config';

@Component({
  selector: 'app-contribute-funds',
  templateUrl: './contribute-funds.component.html',
})
export class ContributeFundsComponent {
  @Input() parentForm?: ContributionFundsFormGroup;
  instruction = 'Start by entering the amount to contribute to the client\'s account.';

  contributionMaskOptions: NgxCurrencyConfig = {
    ...CustomCurrencyMaskConfig, ...{
      allowNegative: false,
      min: 0.00,
      max: 9999999999.99,
    }
  };
}
