import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable, map, of } from 'rxjs';
import { fadeIn } from 'src/app/shared/constants/animations';
import { catchError, mergeMap } from 'rxjs';
import { Account, AccountsFilter } from '../../models/account.models';
import { AccountModel, AgentModel, AgentsClient, InsuranceAccountsClient, InvestmentAccountsClient, PolicyByCrdListModel } from 'src/app/core/clients/generated/client';
import { hasAll } from 'src/app/shared/helpers/search.helpers';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { PaperAppModalComponent } from '../../modals/paper-app-modal/paper-app-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SidenavConfig } from 'src/app/shared/models/generic.models';
import { FilterCheckbox } from '../../../request-tracker/models/request-tracker-models';
import { Permission } from 'src/app/core/clients/generated/client';
import { AccountsListComponent } from '../../components/accounts/accounts-list.component';
import { formatPercent } from '@angular/common';
import { SavedFiltersService } from '../../../../../shared/services/saved-filters.service';
import { OpenSsoAgentSelectModalComponent } from 'src/app/shared/modals/sso-agent-select-modal/sso-agent-select-modal.component';

@Component({
  animations: [fadeIn],
  selector: 'app-accounts-page',
  templateUrl: './accounts-page.component.html',
})
export class AccountsPageComponent implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private agentsApiService: AgentsClient,
    private dialog: MatDialog,
    private insuranceAccountsApi: InsuranceAccountsClient,
    private investmentAccountApi: InvestmentAccountsClient,
    private snackbarService: SnackbarService,
    private titleService: Title,
    private savedFiltersService: SavedFiltersService,
  ) { }
  @ViewChild(AccountsListComponent) private accountList?: AccountsListComponent;
  accounts: Account[] = [];
  advisors: AgentModel[] = [];
  disabled = false;
  filteredAccounts: Account[] = [];
  filteredAdvisors: FilterCheckbox[] = [];
  isArcAdvisor = false;
  isArcAdvisorLimit = 50;
  isLoading = false;
  searchTerm = '';
  advisorSearchTerm = '';
  selectedRadioAdvisorId = 0;
  sidenavConfig: SidenavConfig = {
    Mode: 'side',
    IsOpened: true
  };
  tableFilter = new AccountsFilter();
  perm = Permission;
  hasInsuranceAccounts = false;

  ngOnInit(): void {
    this.isLoading = true;
    this.titleService.setTitle(this.activatedRoute.snapshot.data['title'] + ' | Alphastar Portal');
    this.getAdvisors()
      .pipe(
        map(results => {
          this.advisors = this.initAdvisors(results.selfAdvisor, results.parentAdvisors);
          if (this.advisors.length > this.isArcAdvisorLimit) this.isArcAdvisor = true;

          this.initSelectAdvisors(results.selfAdvisor, results.parentAdvisors);

          // if 50+ advisors, pass initial query with first advisor but pass it as a list, else pass all advisors
          if (this.isArcAdvisor) {
            const advisor = this.advisors.find(a => a.agentID === this.selectedRadioAdvisorId);
            return [advisor as AgentModel];
          }
          return this.advisors;
        }),
        mergeMap(advisors => this.getAccounts(advisors))
      )
      .subscribe({
        next: results => {
          this.combineAccounts(results.investmentAccounts, results.insuranceAccounts);
        }, error: () => {
          this.snackbarService.openSnackbar('Error retrieving Accounts.', ToastClassEnum.warning);
        }
      })
      .add(() => {
        this.isLoading = false;
        if (this.accountList) this.accountList.isLoading = false;
      });

    this.tableFilter.Status = 'All';
  }

  combineAccounts(investmentAccs: AccountModel[], insuranceAccs: PolicyByCrdListModel[]): void {
    this.hasInsuranceAccounts = false;
    const investmentAccounts = investmentAccs.map(this.convertInvestmentAccountToAccount);
    const insuranceAccounts = insuranceAccs.map((policy: PolicyByCrdListModel): Account => this.convertInsurancePolicyToAccount(policy, this.advisors));
    this.accounts = investmentAccounts.concat(insuranceAccounts);
    if (this.accounts.some(a => !a.IsInvestmentAccount))
      this.hasInsuranceAccounts = true;
    this.filterAccounts();
  }

  compareAdvisors(a: AgentModel, b: AgentModel): boolean {
    return a && b && a.agentID === b.agentID;
  }

  convertInsurancePolicyToAccount(policy: PolicyByCrdListModel, advisors: AgentModel[]): Account {
    return {
      Advisors: advisors.filter(a => a.crd === policy.crdNumber).map(a => {
        return {
          AdvisorId: a.agentID,
          Crd: a.crd,
          FirstName: a.firstName,
          LastName: a.lastName,
        };
      }),
      Clients: policy.clients.map(c => {
        return {
          Id: null,
          FirstName: c.firstName,
          LastName: c.lastName,
        };
      }),
      Type: policy.lineOfBusiness,
      Carrier: policy.carrier,
      Product: policy.product,
      Value: policy.currentValue as number,
      CashValue:  null,
      OpenedDate: policy.statusDate as Date,
      Fee: '---',
      Status: policy.status,
      IsInvestmentAccount: false,
      AccountNumber: policy.policyNumber,
      Custodian: null,
      Model: null,
      InsuranceAccountId: policy.policyID,
      InvestmentAccountId: null,
      Sleeves: [],
      ShowSleeves:false,
      CustodianId: null,
      TDSchwabAccountNumbers: [],
    };
  }

  private static calculateAccountFee(account:AccountModel): string {
    if (account.totalFee === 0) return '---';
    if (!account.sleeves.length) return formatPercent(account.totalFee ?? 0, 'en-US', '1.2');
    if (account.sleeves.every(a => a.accountTotalFee === account.sleeves[0].accountTotalFee)) // check if all sleeves have same fee
      return formatPercent(account.sleeves[0].accountTotalFee, 'en-US', '1.2');
    return 'Multiple';
  }

  convertInvestmentAccountToAccount(account: AccountModel): Account {
    return {
      Advisors: account.advisors.map(a => {
        return {
          AdvisorId: a.agentID,
          Crd: a.crd,
          FirstName: a.firstName,
          LastName: a.lastName,
        };
      }),
      Clients: account.clients.map(c => {
        return {
          Id: c.id,
          FirstName: c.firstName,
          LastName: c.lastName,
        };
      }),
      Type: account.investmentAccountType,
      Model: account.investmentAccountModel,
      Custodian: account.custodian,
      CustodianId: account.custodianID as number,
      Value: account.accountValue as number,
      CashValue: account.cashValue as number,
      OpenedDate: account.openedOn as Date,
      Fee: AccountsPageComponent.calculateAccountFee(account),
      Status: account.investmentAccountStatus,
      IsInvestmentAccount: true,
      AccountNumber: account.accountNumber,
      Carrier: null,
      Product: null,
      InsuranceAccountId: null,
      InvestmentAccountId: account.investmentAccountID,
      Sleeves: account.sleeves,
      ShowSleeves:false,
      TDSchwabAccountNumbers: account.tdSchwabAccountNumbers,
    };
  }

  filterAccounts(): void {
    const activeAgentIdFilters: FilterCheckbox[] = this.filteredAdvisors.filter(t => t.Checked);
    //save selected advisorIds to local storage
    const selectedAdvisorIds = activeAgentIdFilters.map(adv => adv.Value) as [];
    this.savedFiltersService.setSelectedAdvisors(selectedAdvisorIds);

    this.filteredAccounts = this.accounts.filter(a => {
      const accountAgentIds = a.Advisors.map(adv => adv.AdvisorId);
      const accountContainsAgent = accountAgentIds?.some(id => activeAgentIdFilters.some(a => a.Value === id));
      const typeFilters: FilterCheckbox[] = this.tableFilter.Types.filter(t => t.Checked);
      if (!accountContainsAgent || (typeFilters.length && !typeFilters.some(t => t.Value === 'Investment' ? a.IsInvestmentAccount : t.Value === 'Insurance' ? !a.IsInvestmentAccount : a.IsInvestmentAccount && !a.IsInvestmentAccount))) {
        return false;
      }
      //TODO: figure out how we're going to classify insurance accounts as open/closed etc.
      /*if (this.tableFilter.Status !=='' && this.tableFilter.Status !=='All') {
        // 'Open' filters list to Submitted/Pending requests; 'Closed' filters list to Completed/Cancelled requests
        if (this.tableFilter.Status === 'Active')
          return (a.Status?.includes('Active') || a.Status?.includes('Inforce') || a.Status?.includes('Pending')) && hasAll(a, this.searchTerm);
        if (this.tableFilter.Status === 'Closed')
          return (a.Status?.includes('Closed') || a.Status?.includes('Canceled') || a.Status?.includes('Withdrawn') || a.Status?.includes('Not Taken') || a.Status?.includes('Completed')) && hasAll(a, this.searchTerm);
      }*/
      return hasAll(a, this.searchTerm);
    });
  }

  /**Use radio buttons when selecting from 50+ advisors to prevent excessive load time
   * and allow picking one advisor at a time.
   * @param filteredAdvisorId model of selected advisor
  */
  radioAdvisorChecked(filteredAdvisorId: string | number): void {
    this.selectRadioOption(filteredAdvisorId);
    this.loadSingleAdvisorAccounts(filteredAdvisorId);
  }

  selectRadioOption (filteredAdvisorId: string | number): void {
    if (this.filteredAdvisors.some(a => a.Value === this.selectedRadioAdvisorId)) {
      const oldSelectedRadioAdvisor = this.filteredAdvisors.find(a => a.Value === this.selectedRadioAdvisorId) as FilterCheckbox;
      oldSelectedRadioAdvisor.Checked = false;
    }

    this.selectedRadioAdvisorId = Number(filteredAdvisorId);
    const newSelectedRadioAdvisor = this.filteredAdvisors.find(a => a.Value === this.selectedRadioAdvisorId) as FilterCheckbox;
    newSelectedRadioAdvisor.Checked = true;
  }

  loadSingleAdvisorAccounts (filteredAdvisorId: string | number): void {
    const advisor = this.advisors.find(a => a.agentID === filteredAdvisorId);
    this.isLoading = true;
    this.getAccounts([advisor as AgentModel])
      .subscribe({
        next: results => {
          this.combineAccounts(results.investmentAccounts, results.insuranceAccounts);
        },
        error: () => {
          this.snackbarService.openSnackbar('Error retrieving Accounts.', ToastClassEnum.warning);
        }
      })
      .add(() => {
        this.isLoading = false;
        if (this.accountList) this.accountList.isLoading = false;
      });
  }

  /**
   * Get insurance and investment accounts.
   * If an error is thrown for either call continue to load other accounts
   * @param advisors list of agents whose accounts will be queried
   * @return {insuranceAccounts,investmentAccounts} observable
  */
  getAccounts(advisors: AgentModel[]): Observable<{ insuranceAccounts: PolicyByCrdListModel[]; investmentAccounts: AccountModel[]; }> {
    const insuranceAccountHttp = this.insuranceAccountsApi.getInsuranceAccountsByCrds(advisors.map(a => a.crd).filter(crd => !!crd))
      .pipe(catchError(() => {
        this.snackbarService.openSnackbar('Unable to retrieve insurance accounts', ToastClassEnum.warning);
        return of([]);
      }));
    const investmentAccountHttp = this.investmentAccountApi.getInvestmentAccountsByAgentIds(advisors.map(a => a.agentID))
      .pipe(catchError(() => {
        this.snackbarService.openSnackbar('Unable to retrieve investment accounts', ToastClassEnum.warning);
        return of([]);
      }));
    return forkJoin({
      insuranceAccounts: advisors.some(a => a.crd) ? insuranceAccountHttp : of([]), // Don't make a call to get insuarance accounts if there are no crd's to pass to the backend.
      investmentAccounts: investmentAccountHttp
    });
  }

  getAdvisors(): Observable<{ parentAdvisors: AgentModel[]; selfAdvisor: AgentModel | null; }> {
    return forkJoin({
      parentAdvisors: this.agentsApiService.getParentAgents(false),
      selfAdvisor: this.agentsApiService.getAgentSelf()
    });
  }

  initAdvisors(selfAdvisor: AgentModel | null, parentAdvisors: AgentModel[]): AgentModel[] {
    // combine parent advisors and self advisor
    let advisors: AgentModel[] = parentAdvisors;
    if (selfAdvisor !== null) {
      advisors = parentAdvisors.concat([selfAdvisor]);
    }
    return advisors;
  }

  initSelectAdvisors(selfAdvisor: AgentModel | null, parentAdvisors: AgentModel[]): void {

    //remove self from parentAdvisors if in the list so we don't have duplication
    parentAdvisors = selfAdvisor ? parentAdvisors.filter(p => (p.agentID != selfAdvisor?.agentID)) : parentAdvisors;

    // for preselect parent and self advisors if the user hasn't any saved in local storage yet
    const preSelectedAdvisors = parentAdvisors.concat([selfAdvisor as AgentModel]);
    const savedAdvisors = this.savedFiltersService.getSelectedAdvisors();
    parentAdvisors.forEach(a => {
      this.tableFilter.Advisors.push({
        Name: a.firstName + ' ' + a.lastName,
        Value: a.agentID,
        Checked: this.isArcAdvisor ? false : savedAdvisors.length > 0 ? savedAdvisors.some(x => x === a.agentID) : preSelectedAdvisors.some(x => x.agentID === a.agentID),
      });
    });
    if (selfAdvisor) {
      this.tableFilter.Advisors.unshift({
        Name: selfAdvisor.firstName + ' ' + selfAdvisor.lastName,
        Value: selfAdvisor.agentID,
        Checked: this.isArcAdvisor ? false : savedAdvisors.length > 0 ? savedAdvisors.some(a => a === selfAdvisor.agentID) : preSelectedAdvisors.some(a => a.agentID === selfAdvisor.agentID),
      });
    }

    this.filterAdvisorList();

    //set index for radio list if this.isArcAdvisor is true
    if (this.isArcAdvisor === true) {
      //load the the first saved advisorId if any else load the first advisor
      const agentId = savedAdvisors.length > 0 ? savedAdvisors[0] : this.tableFilter.Advisors[0].Value;

      //go ahead and set radio list option checked
      this.selectRadioOption(agentId);
    }
  }

  filterAdvisorList(): void {
    this.filteredAdvisors = this.tableFilter.Advisors.filter(a => {
      return hasAll(a, this.advisorSearchTerm);
    });
  }

  openPaperAppModal(): void {
    this.dialog.open<PaperAppModalComponent>(PaperAppModalComponent, {
      width: '50rem',
      height: '80rem',
      autoFocus: false,
    }).afterClosed()
      .subscribe(isSuccess => {
        if (isSuccess) {
          this.getAccounts(this.advisors).subscribe({
            next: results => {
              this.combineAccounts(results.investmentAccounts, results.insuranceAccounts);
            }, error: () => {
              this.snackbarService.openSnackbar('Error retrieving Accounts.', ToastClassEnum.warning);
              this.isLoading = false;
            }
          });
        }
      });
  }

  openOrionAgentSelectModal(destination:string|null): void {
    this.dialog.open(OpenSsoAgentSelectModalComponent, {
      minHeight: '28rem',
      autoFocus: false,
      data: {BaseUrl: 'api/sso/orion/portal/' + destination + '?appearAsCrmAgentId='}
    });
  }
}
