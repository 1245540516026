import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { customHoldingsModelID } from 'src/app/portal/features/account-request-workflows/shared/constants/custom-holdings-model-id.constant';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { NgxCurrencyConfig } from 'ngx-currency';
import { debounceTime, distinctUntilChanged, map, Observable, startWith } from 'rxjs';
import { InvestmentModel, InvestmentModelsClient } from 'src/app/core/clients/generated/client';
import { CustomCurrencyMaskConfig } from 'src/app/shared/config/currency-mask.config';
import { strictSearch } from 'src/app/shared/helpers/search.helpers';
import { InvestmentRequestFormService } from '../../../../services/investment-request-form.service';
import { AllocationFormGroup, NewSleeveFormGroup } from '../../../models/allocation-form.models';
import { Dollar, Percent } from '../../../constants/allocation-action.constant';

@Component({
  selector: 'app-allocation-add-sleeve',
  templateUrl: './allocation-add-sleeve.component.html',
})
export class AllocationAddSleeveComponent implements OnInit, OnDestroy {
  filteredModels?: Observable<InvestmentModel[]>;
  allModels: InvestmentModel[] = [];
  @Input() form?: AllocationFormGroup;
  @Input() initialAmount?: number;
  @Input() investmentRequestFormService?: InvestmentRequestFormService;
  @Output() newSleeveAdded = new EventEmitter<{sleeve: NewSleeveFormGroup, showCustomHoldings: boolean}>();
  @Output() customHoldingSleeveAdded = new EventEmitter();
  Dollar = Dollar;
  Percent = Percent;
  newSleeve?: NewSleeveFormGroup;
  accountTotalFeeMaskOptions: NgxCurrencyConfig = {
    ...CustomCurrencyMaskConfig, ...{
      prefix: '',
      suffix: '%',
      min: 0,
      max: 100,
      precision: 3,
    }
  };
  sleevePercentageMaskOptions: NgxCurrencyConfig = {
    ...CustomCurrencyMaskConfig, ...{
      decimal: '.',
      precision: 2,
      prefix: '',
      suffix: '%',
      min: 0,
      max: 100,
    }
  };
  constructor(private fb: UntypedFormBuilder, private modelsClient: InvestmentModelsClient) { }

  ngOnInit(): void {
    this.newSleeve = this.form?.controls.newSleeve;
    if (!this.newSleeve?.controls.showAddNewSleeve.value as boolean) {
      this.clearNewSleeve();
    }

    const agentIds = this.investmentRequestFormService?.account?.advisors.map(a => a.agentID) || [];
    this.modelsClient.getAgentInvestmentModels(agentIds)
      .subscribe(models => {
        this.allModels = models;
        this.filteredModels = this.newSleeve?.controls.searchTerm.valueChanges.pipe(
          startWith(''),
          debounceTime(300),
          distinctUntilChanged(),
          map(searchTerm => this.filterModels(searchTerm as string))
        );
      });
  }

  ngOnDestroy(): void {
    this.addNewSleeve(false);
    this.clearNewSleeve();
  }

  filterModels(searchTerm: string): InvestmentModel[] {
    const sleeveIDs = this.form?.controls.sleeves?.value.map(sleeve => sleeve.id);
    const nonDuplicatedModels = this.allModels.filter(model => !sleeveIDs?.includes(model.id));
    return strictSearch(nonDuplicatedModels, searchTerm, ['name']);
  }

  addNewSleeve(showCustomHoldings: boolean): void {
    if (this.newSleeve?.valid && this.newSleeve?.controls.showAddNewSleeve.value as boolean) {
      this.newSleeveAdded.next({
        sleeve: this.newSleeve,
        showCustomHoldings: showCustomHoldings
      });
      this.clearNewSleeve();
    }
  }

  addNewCustomHoldingsSleeve(): void {
    if (this.newSleeve?.valid && this.newSleeve?.controls.showAddNewSleeve.value as boolean) {
      this.customHoldingSleeveAdded.next(this.newSleeve);
      this.clearNewSleeve();
    }
  }

  showNewSleeve(isCustomHoldings: boolean): void {
    const canShowSleeve = this.newSleeve?.controls.showAddNewSleeve.value as boolean;
    if (!canShowSleeve) {
      this.initiateNewSleeve(isCustomHoldings);
    } else {
      this.clearNewSleeve();
    }
  }

  removeNewSleeve(): void {
    this.clearNewSleeve();
  }

  initiateNewSleeve(isCustomHoldings: boolean): void {

    if (isCustomHoldings) {
      //this.newSleeve?.controls.model.setValue(customHoldingsModelID);

      const customHoldingModel = this.allModels.find((obj) => {
        return obj.id === customHoldingsModelID;
      });

      this.newSleeve?.controls.searchTerm.setValue({
        id: customHoldingModel?.id,
        name: customHoldingModel?.name,
      });

      this.newSleeve?.controls.model.setValue({
        id: customHoldingModel?.id,
        name: customHoldingModel?.name,
      });
    }

    this.newSleeve?.controls.showAddNewSleeve.setValue(true);
    this.newSleeve?.controls.accountTotalFee.addValidators(Validators.required);
    this.newSleeve?.controls.amount.addValidators(Validators.required);
    this.newSleeve?.controls.model.addValidators(Validators.required);
    this.newSleeve?.controls.isCustomHoldings.setValue(isCustomHoldings);
  }

  clearNewSleeve(): void {
    this.newSleeve?.controls.showAddNewSleeve.setValue(false);
    this.newSleeve?.controls.accountTotalFee.clearValidators();
    this.newSleeve?.controls.amount.clearValidators();
    this.newSleeve?.controls.model.clearValidators();
    this.newSleeve?.controls.accountTotalFee.setValue(null);
    this.newSleeve?.controls.amount.setValue(null);
    this.newSleeve?.controls.model.setValue(null);
    this.newSleeve?.controls.searchTerm.setValue(null);
    this.newSleeve?.controls.isCustomHoldings.setValue(false);
  }

  customHoldingsSleeveExists(): boolean {
    const customHoldingsSleeve = this.form?.controls.sleeves.controls.find((obj) => {
      return obj.value.id === customHoldingsModelID;
    });

    if (customHoldingsSleeve) {
      return true;
    }

    return false;
  }

  displayModel(model: InvestmentModel): string {
    if (model) {
      return model.name;
    } else {
      return '';
    }
  }

  onChangeModelSearch($event: MatAutocompleteSelectedEvent): void {
    this.newSleeve?.controls.model.setValue($event.option.value);

    const isCustomSleeve = this.newSleeve?.value.model.id === customHoldingsModelID ?? false;
    this.newSleeve?.controls.isCustomHoldings.setValue(isCustomSleeve);
  }
}
