import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgxCurrencyConfig} from 'ngx-currency';
import { distinctUntilChanged, debounceTime, Subscription } from 'rxjs';
import { AccountModel } from 'src/app/core/clients/generated/client';
import { CustomCurrencyMaskConfig } from 'src/app/shared/config/currency-mask.config';
import { fadeIn } from 'src/app/shared/constants/animations';
import { Gross, Net } from '../../../shared/constants/distribution-action.constants';
import { DistributionAmountInputFormGroup } from '../../models/distribution-form.model';

@Component({
  selector: 'app-distribute-funds',
  templateUrl: './distribute-funds.component.html',
  animations: [fadeIn]
})
export class DistributeFundsComponent implements OnInit, OnDestroy {
  @Input() account?: AccountModel;
  @Input() parentForm?: DistributionAmountInputFormGroup;
  Gross = Gross;
  Net = Net;
  displayGrossAmount: number | undefined;
  displayNetAmount: number | undefined;
  displayNetGrossInformationBox = false;
  netAmountCurrencyMaskOptions: NgxCurrencyConfig = {
    ...CustomCurrencyMaskConfig, ...{
      allowNegative: false,
      min: 0.00,
      max: 9999999999.99,
    }
  };
  grossAmountCurrencyMaskOptions: NgxCurrencyConfig = {
    ...CustomCurrencyMaskConfig, ...{
      allowNegative: false,
      min: 0.00,
      max: 9999999999.99,
    }
  };

  hasAlert = false;

  private amountSubscription?: Subscription;
  private typeSubscription?: Subscription;
  private netAmountSubscription?: Subscription;
  private grossAmountSubscription?: Subscription;
  private stateTaxAmountSubscription?: Subscription;
  private federalTaxAmountSubscription?: Subscription;

  ngOnInit(): void {
    this.displayGrossAmount = this.parentForm?.controls.grossAmount.value as number ?? undefined;
    this.displayNetAmount = this.parentForm?.controls.netAmount.value as number ?? undefined;
    if (this.displayGrossAmount || this.displayNetAmount) {
      this.displayNetGrossInformationBox = true;
      this.parentForm?.controls.stateTaxAmount.enable();
      this.parentForm?.controls.federalTaxAmount.enable();
    } else {
      this.displayNetGrossInformationBox = false;
      this.parentForm?.controls.stateTaxAmount.disable();
      this.parentForm?.controls.federalTaxAmount.disable();
    }

    this.setupValueChangeDetection();
  }

  ngOnDestroy(): void {
    this.unsubscribeChangeDetection();
  }

  /** Check to display warning when dist. amount > account value */
  checkIfBalanceIsNegative(accountValue?: number, grossAmount = 0): void {
    if (!accountValue) {
      this.hasAlert = false;
      return;
    }
    if (accountValue < grossAmount) this.hasAlert = true;
    else this.hasAlert = false;
  }

  unsubscribeChangeDetection(): void {
    this.amountSubscription?.unsubscribe();
    this.typeSubscription?.unsubscribe();
    this.stateTaxAmountSubscription?.unsubscribe();
    this.federalTaxAmountSubscription?.unsubscribe();
  }

  // DistinctUntilChanged won't work om amount inputs as the control may be updated manually or programmatically.
  // In case of the latter, emitChange event won't emit and won't be caught by change detection next time user happens to enter a previously matching value
  setupValueChangeDetection(): void {
    if (this.parentForm) {

      this.amountSubscription = this.parentForm?.controls.typeAmount?.valueChanges.pipe(distinctUntilChanged(), debounceTime(500)).subscribe({
        next: amount => {
          if (amount > 0 && !Number.isNaN(amount)) {
            this.recalculate();
          } else {
            this.parentForm?.controls.typeAmount.patchValue(null);
          }
        }
      });

      this.typeSubscription = this.parentForm?.controls.type?.valueChanges.pipe(distinctUntilChanged(), debounceTime(600)).subscribe({
        next: type => {
          if (type) {
            this.recalculate();
          }
          return;
        }
      });

      this.stateTaxAmountSubscription = this.parentForm?.controls.stateTaxAmount.valueChanges.pipe(distinctUntilChanged(), debounceTime(600)).subscribe({
        next: stateTaxAmount => {
          this.parentForm?.controls.stateTaxAmount.patchValue(stateTaxAmount);
          if (!Number.isNaN(stateTaxAmount)) {
            this.recalculate();
          }
        }
      });

      this.federalTaxAmountSubscription = this.parentForm?.controls.federalTaxAmount.valueChanges.pipe(distinctUntilChanged(), debounceTime(1000)).subscribe({
        next: federalTaxAmount => {
          this.parentForm?.controls.federalTaxAmount.patchValue(federalTaxAmount);
          if (!Number.isNaN(federalTaxAmount)) {
            this.recalculate();
          }
        }
      });
    }
  }

  /** Contextually determines which calculation should be performed. */
  recalculate(): void {
    if (this.parentForm) {
      const amount = Number(this.parentForm?.controls.typeAmount.value);
      if (this.parentForm?.controls.type.value === Gross){
        this.updateNetAmount(this.parentForm, amount);
      }

      if (this.parentForm?.controls.type.value === Net){
        this.updateGrossAmount(this.parentForm, amount);
      }

      this.checkIfBalanceIsNegative(this.account?.accountValue, this.parentForm.value.grossAmount);
    }
  }

  /** Calculates the net amount based on the gross amount and any applicable tax withholding.
   *
   * **Formula:**
   * Net Amount = Gross Amount * (1 - (Federal Tax + State Tax))
   * **Example:**
   * Gross Amount = 100,000
   * Federal Tax = 10%
   * State Tax = 10%
   * Net Amount = 80,000
   */
  updateNetAmount(parentForm: DistributionAmountInputFormGroup, value: number | null): void {
    const stateTaxAmount = parentForm.controls.stateTaxAmount.disabled ? 0 : parentForm.value.stateTaxAmount / 100;
    const federalTaxAmount = parentForm.controls.federalTaxAmount.disabled ? 0 : parentForm.value.federalTaxAmount / 100;
    const grossAmount = value ? value : parentForm.value.typeAmount;
    const rawNetAmount = grossAmount * (1 - (federalTaxAmount + stateTaxAmount));
    /** Normalize to 2 decimal places. Round up if >= .05, down if < .05 */
    const normalizedNetAmount = Number(rawNetAmount.toFixed(2));
    parentForm.controls.netAmount.patchValue(normalizedNetAmount <= 0 ? null : normalizedNetAmount, {
      emitEvent: false,
    });
    parentForm.controls.grossAmount.patchValue(grossAmount <= 0 ? null : grossAmount, {
      emitEvent: false,
    });

    this.displayGrossAmount = grossAmount;
    this.displayNetAmount = normalizedNetAmount === grossAmount ? undefined : normalizedNetAmount;
    this.displayNetGrossInformationBox = grossAmount ? true : false;
    if (value) {
      this.parentForm?.controls.stateTaxAmount.enable();
      this.parentForm?.controls.federalTaxAmount.enable();
    }
  }

  /** Calculates the gross amount based on the net amount and any applicable tax withholding.
   *
   * **Formula:**
   * Gross Amount = Net Amount / (1 - (Federal Tax + State Tax))
   * **Example:**
   * Net Amount = 100,000
   * Federal Tax = 10%
   * State Tax = 10%
   * Gross Amount = 120,000
   */
  updateGrossAmount(parentForm: DistributionAmountInputFormGroup, value: number | null): void {
    const stateTaxAmount = parentForm.controls.stateTaxAmount.disabled ? 0 : parentForm.value.stateTaxAmount / 100;
    const federalTaxAmount = parentForm.controls.federalTaxAmount.disabled ? 0 : parentForm.value.federalTaxAmount / 100;
    const netAmount = value ? value : parentForm.value.typeAmount;
    if ((1 - (federalTaxAmount + stateTaxAmount)) !== 0) {
      const rawGrossAmount = netAmount / (1 - (federalTaxAmount + stateTaxAmount));
      /** Normalize to 2 decimal places. Round up if >= .05, down if < .05 */
      const normalizedGrossAmount = Number(rawGrossAmount.toFixed(2));
      parentForm.controls.grossAmount.patchValue(normalizedGrossAmount <= 0 ? null : normalizedGrossAmount, {
        emitEvent: false,
      });
      parentForm.controls.netAmount.patchValue(netAmount <= 0 ? null : netAmount, {
        emitEvent: false,
      });

      this.displayGrossAmount = normalizedGrossAmount;
      this.displayNetAmount = netAmount === normalizedGrossAmount ? undefined : netAmount;
      this.displayNetGrossInformationBox = netAmount ? true : false;
      if (value) {
        this.parentForm?.controls.stateTaxAmount.enable();
        this.parentForm?.controls.federalTaxAmount.enable();
      }
    }
  }
}