@if (form && requestType) {
  <form class="flex flex-col w-full h-full overflow-hidden" [formGroup]="form">
    <section class="flex flex-col flex-grow overflow-auto p-30 gap-y-30 gap-x-20">
      <p class="text-lg">Please specify the individual shares you would like to buy. When you're finished, click
        <strong>Done</strong> to return to allocation.
      </p>
      <app-custom-holdings-allocation [investmentRequestFormService]="investmentRequestFormService" [parentForm]="form" [newCustomHolding]="newCustomHoldingForm" [customHoldingsAmount]="customHoldingsAmount"
      [requestType]="requestType"></app-custom-holdings-allocation>
      <app-custom-holdings-notes [parentForm]="form"></app-custom-holdings-notes>
    </section>
    <footer>
      <button  [disabled]="!this.form.valid" (click)="done()" class="m-10 ml-auto button is-primary min-w-128 is-lg" mat-button type="button">Done</button>
    </footer>
  </form>
}
