<section class="flex flex-col flex-none p-20 border-b border-solid gap-y-10 border-gray-lighter">
  <ng-container  *appShowIfAll="perm.ViewPaytrace">
    <a mat-button class="button flex is-primary is-inverted justify-start" href="https://paylink.paytrace.com?m=eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMTI4R0NNIn0.awteUlcWFdWsigBIlChNGzo1-6FhZ7MF.SzfBC3o8qA78ej4p.6MFcZE12N7GaQVf8qTNQWJ0LMdy0S5Cjz30m1SuL1cZpp9jF4ZVNO_atjKFbWjcQHsqoIm07gwurgaFSISdnIlZNqqgqVtZVpcVw38ZHhSAaI4R9c9hQi9EdKzmPAec9p-TXt2LwjSxbfIBot_tgY0QdeMSLGphzDEDHdDbfJToj2om8FR7oBMCZ_tuGGZtG45tA-_dLDrHbF9G9KKhlrnaFGvJB7J-_mujXArxxxeq3yET5i0_NEgxzg3gRqat4c_JcRYNyYr4fe5mHpsv6zwnGCneYOBMylkwM7w.t4PUDzSyHL700daa6peM0Q&amount=&invoice=" target="_blank" rel="noopener">
      <span class="flex items-center justify-start">
        <span class="mr-10 mi-18 material-icons">monetization_on</span>
        <span>Make A Payment</span>
      </span>
    </a>
  </ng-container>
  <button mat-button class="button is-primary is-inverted is-warning justify-start" aria-label="Logout Now" (click)="logout()">
    <span class="flex items-center justify-start">
      <span class="mr-10 mi-18 material-icons">power_settings_new</span>
      <span>Logout</span>
    </span>
  </button>
</section>