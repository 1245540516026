
<section class="flex w-full h-full p-20 md:p-30 bg-top bg-no-repeat bg-cover" style="background: url(./assets/images/wave-bg.svg)">
  <section class="flex w-full h-full" *appHideIfOne="perm.ViewEvolvExperience">
    @if (!isLoading) {
      <section class="grid w-full grid-cols-6 gap-20 mx-auto lg:gap-30"
        [@fadeIn]="!isLoading">
        <!-- <section class="flex col-span-4">
        <h1 class="mr-20 text-lg font-bold capitalize">Development Center</h1>
        </section> -->
        <section class="flex flex-col text-center col-span-6 lg:col-span-2 md:col-span-2 sm:col-span-6 card p-30 gap-y-5">
          <div class="flex flex-col gap-y-10 pb-10">
            <h2 class="font-bold">Recent Communications</h2>
            <!-- <p class="text-sm text-gray h-36">Stay up to date about all things Alphastar.</p> -->
          </div>
          <div class="flex overflow-auto h-280">
            @if (recentCommunicationSection?.AllAssets) {
              <ul class="flex flex-col w-full">
                @for (a of recentCommunicationSection?.AllAssets; track a) {
                  <li class="flex p-10 text-sm">
                    <div class="w-full" [class]="a.Asset.mediaType === 'Video' ? 'wistia_embed wistia_async_' + a.Asset.assetId + ' popover=true popoverContent=link inline relative' : ''">
                      <div class="flex justify-start items-center">
                        @if (a.Asset.mediaType === 'File') {
                          <img class="h-24"
                            src="assets/images/ico_document.png" />
                        }
                        @if (a.Asset.mediaType === 'Video') {
                          <img class="h-24"
                            src="assets/images/ico_video.png" />
                        }
                        <a class="ml-10 font-semibold underline text-gray-dark text-m hover:text-blue-dark focus:text-blue-dark"
                          [href]="a.Asset.mediaType === 'Video' ? '#' : 'api/wistia/assets/' + a.Asset.assetId"
                          [attr.target]="a.Asset.mediaType === 'Video' ? null : '_blank'"
                        [attr.aria-label]="'Open ' + a.Asset.name" rel="noopener">{{a.Asset.name}}</a>
                      </div>
                    </div>
                  </li>
                }
              </ul>
            }
            @if (!recentCommunicationSection?.AllAssets) {
              <div class="mt-50 text-blue">
                Stay tuned! New details coming soon!
              </div>
            }
          </div>
        </section>
        <section class="flex flex-col text-center col-span-6 lg:col-span-2 md:col-span-2 sm:col-span-6 card p-30 gap-y-5">
          <div class="flex flex-col gap-y-10">
            <h2 class="font-bold">Coffee with Compliance</h2>
            <p class="text-sm text-gray h-64">Quick, digestible tips and reminders to help you stay protected.</p>
          </div>
          <p class="text-sm font-semibold">{{coffeeComplianceHighlightVideo?.name}}</p>
          <div class="mx-auto overflow-hidden rounded wistia_responsive_wrapper h-150 w-256">
            <span
            class="wistia_embed wistia_async_{{coffeeComplianceHighlightVideo?.assetId}} popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only h-full w-full inline-block"> </span>
          </div>
          <a [routerLink]="['../coffee-with-compliance']" routerLinkActive="router-link-active"
            class="flex items-center mt-auto ml-auto text-xs font-bold uppercase gap-x-5">
            <span>View More</span>
            <mat-icon svgIcon="arrow-right" class="mi-18"></mat-icon>
          </a>
        </section>
        <section class="flex flex-col text-center col-span-6 lg:col-span-2 md:col-span-2 sm:col-span-6 card p-30 gap-y-5">
          <div class="flex flex-col gap-y-10">
            <h2 class="font-bold">Webinars</h2>
            <p class="text-sm text-gray h-64">Stay informed and up-to-date through our collection of recorded webinars.</p>
          </div>
          <p class="text-sm font-semibold">{{virtualLearningHighlightVideo?.name}}</p>
          <div class="mx-auto overflow-hidden rounded wistia_responsive_wrapper h-150 w-256">
            <span
            class="wistia_embed wistia_async_{{virtualLearningHighlightVideo?.assetId}} popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only h-full w-full inline-block"> </span>
          </div>
          <a [routerLink]="['../virtual-learning-series']" routerLinkActive="router-link-active"
            class="flex items-center mt-auto ml-auto text-xs font-bold uppercase gap-x-5">
            <span>View More</span>
            <mat-icon svgIcon="arrow-right" class="mi-18"></mat-icon>
          </a>
        </section>
        <section class="flex flex-col text-center col-span-6 lg:col-span-2 md:col-span-2 sm:col-span-6 card p-30 gap-y-5">
          <div class="flex flex-col gap-y-10">
            <h2 class="font-bold">Sales Summit 2024</h2>
            <p class="text-sm text-gray h-64">
              Check out some content from our last Sales Summit from March 2024.
            </p>
          </div>
          <p class="text-sm font-semibold">{{salesSummit2024HighlightVideo?.name}}</p>
          <div class="mx-auto overflow-hidden rounded wistia_responsive_wrapper h-150 w-256">
            <span
            class="wistia_embed wistia_async_{{salesSummit2024HighlightVideo?.assetId}} popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only h-full w-full inline-block"> </span>
          </div>
          <a [routerLink]="['../sales-summit-2024']" routerLinkActive="router-link-active"
            class="flex items-center mt-auto ml-auto text-xs font-bold uppercase gap-x-5">
            <span>View More</span>
            <mat-icon svgIcon="arrow-right" class="mi-18"></mat-icon>
          </a>
        </section>
        @if (showNextLevelSection) {
          <section class="flex flex-row items-center col-span-4 lg:col-span-4 md:col-span-4 sm:col-span-6 card p-30 gap-x-30 gap-y-30">
            <div class="flex items-center w-full mr-auto gap-x-30">
              <div class="block w-full max-w-350 min-w-192">
                <img class="block w-auto mx-auto h-128 " src="assets/images/logos/next-level-logo.png"
                  alt="Next Level Resources">
              </div>
              <div class="flex flex-col gap-y-10">
                <p>Gain access to a solutions-based development program, led by industry expert and coach, Paul Housey, CPA,
                  CFP®, CLU®, designed to empower you with the necessary skills and knowledge to take your practice to the
                Next Level.</p>
                <a [routerLink]="['../next-level']" routerLinkActive="router-link-active"
                  class="flex items-center justify-center mt-10 mr-auto text-xs font-bold uppercase gap-x-5">
                  <span>View More</span>
                  <mat-icon svgIcon="arrow-right" class="mi-18"></mat-icon>
                </a>
              </div>
            </div>
            <div class="flex flex-row items-center justify-between mx-auto w-400 gap-x-40">
              @for (asset of nextLevelSection?.VideoAssets; track asset; let index = $index) {
                <app-development-center-wistia-media class="justify-center"
                  [model]="asset">
                </app-development-center-wistia-media>
              }
            </div>
          </section>
        }
      </section>
    }
  </section>
  <section class="flex w-full h-full" *appShowIfOne="perm.ViewEvolvExperience">
    @if (!isLoading) {
      <section class="grid w-full grid-cols-3 gap-20 mr-auto max-w-1440 lg:gap-30"
        [@fadeIn]="!isLoading">
        <section class="flex col-span-3">
          <h1 class="mr-20 text-lg font-bold capitalize text-white">Development Center</h1>
        </section>
        <section class="flex flex-col col-span-3 text-center lg:col-span-1 card p-30 gap-y-20">
          <div class="flex flex-col gap-y-10">
            <div class="flex flex-col items-center self-center mx-auto rounded">
              <img class="block w-auto h-32" src="assets/images/logos/evolv-logo-square.png" alt="Evolv">
            </div>
            <h2 class="font-bold">Evolv Your Practice</h2>
            <p class="text-sm text-gray mb-30">Evolv Family Wealth Personal CFO solution allows you to be the single point
              of contact for all aspects of a client's financial picture. The platform's education, processes, templates,
              key partners, and resources are designed to show you exactly how to build a family office style
            business.<br /><br /><br /></p>
          </div>
          <!--<p class="text-sm font-semibold">Evolv Your Practice</p>-->
          <div class="mx-auto overflow-hidden rounded wistia_responsive_wrapper h-150 w-256">
            <span
            class="wistia_embed wistia_async_29sghwldwv popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only h-full w-full inline-block"> </span>
          </div>
        </section>
        <section class="flex flex-col col-span-3 text-center lg:col-span-1 card p-30 gap-y-20">
          <div class="flex flex-col gap-y-10">
            <div class="flex flex-col items-center self-center mx-auto rounded">
              <img class="block w-auto h-32" src="assets/images/logos/evolv-logo-square.png" alt="Evolv">
            </div>
            <h2 class="font-bold">Evolv Your Wealth</h2>
            <p class="text-sm text-gray mb-30">Evolv Family Wealth fills the gaps of time and knowledge to deliver peace of
              mind and balance between the life and wealth of the families we work with. Your Evolv Personal CFO is the
              single point of contact for the many aspects of your financial picture. We are responsible for meeting with
              your experts, sitting down with your attorney, CPA, insurance agent, real estate agent, and so on. This
            ensures that everyone is working toward the same goals, YOUR goals, not theirs.</p>
          </div>
          <div class="mx-auto overflow-hidden rounded wistia_responsive_wrapper h-150 w-256">
            <span
            class="wistia_embed wistia_async_gbd82ywqbn popover=true popoverAnimateThumbnail=false videoFoam=false videoQuality=hd-only h-full w-full inline-block"> </span>
          </div>
        </section>
        <section class="flex flex-col col-span-3 text-center lg:col-span-2 card p-30 gap-y-20">
          <div class="flex flex-col gap-y-10">
            <div class="flex flex-col items-center self-center mx-auto rounded">
              <img class="block w-auto h-32" src="assets/images/logos/evolv-logo-square.png" alt="Evolv">
            </div>
            <h2 class="font-bold">Evolve Resources</h2>
            <p class="text-sm text-gray mb-20">Check out Evolv's centralized home for up-to-date tools, templates, and other
            resources.</p>
            <div class="flex flex-row justify-center">
              <img class="h-24" src="assets/images/ico_document.png" />
              <a class="ml-10 font-semibold underline text-gray-dark text-m hover:text-blue-dark focus:text-blue-dark"
                href='https://evolvfamilywealth.sharefile.com/home/shared/fo41eec4-84ca-4a26-8af6-62833595401d'
              target="_blank" [attr.aria-label]="'Open Evolv ShareFile'" rel="noopener">Open Evolv ShareFile</a>
            </div>
          </div>
        </section>
      </section>
    }
  </section>
</section>
