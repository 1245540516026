import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { AgentModel, AgentsClient, RequestModel, RequestsClient } from 'src/app/core/clients/generated/client';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { fadeIn } from 'src/app/shared/constants/animations';
import { hasAll } from 'src/app/shared/helpers/search.helpers';
import { SidenavConfig } from 'src/app/shared/models/generic.models';
import { RequestTrackerFilter } from '../constants/request-tracker.constants';
import { FilterCheckbox, RequestTrackerTypeEnum } from '../models/request-tracker-models';
import { ActivatedRoute } from '@angular/router';
import { RequestTrackerListComponent } from '../components/request-tracker-list/request-tracker-list.component';
import { SavedFiltersService } from 'src/app/shared/services/saved-filters.service';
import { OrderDirection } from 'src/app/shared/helpers/table.helpers';

@Component({
  animations: [fadeIn],
  selector: 'app-request-tracker-page',
  templateUrl: './request-tracker-page.component.html',
})
export class RequestTrackerPageComponent implements OnInit {
  constructor(
    private agentsApiService: AgentsClient,
    private activatedRoute: ActivatedRoute,
    private requestsApiService: RequestsClient,
    private snackbarService: SnackbarService,
    private titleService: Title,
    private savedFiltersService: SavedFiltersService,
  ) { }
  @ViewChild(RequestTrackerListComponent) private requestList?: RequestTrackerListComponent;
  advisors: AgentModel[] = [];
  disabled = false;
  filteredRequests: RequestModel[] = [];
  filteredAdvisors: FilterCheckbox[] = [];
  isLoading = false;
  isArcAdvisor = false;
  isArcAdvisorLimit = 50;
  requests: RequestModel[] = [];
  searchTerm = '';
  advisorSearchTerm = '';
  selectedRadioAdvisorId = 0;
  sidenavConfig: SidenavConfig = {
    Mode: 'side',
    IsOpened: true
  };
  tableFilter = new RequestTrackerFilter();
  sortDirection: OrderDirection = 'asc';
  filterStatus = '';

  ngOnInit(): void {
    this.isLoading = true;
    this.titleService.setTitle(this.activatedRoute.snapshot.data['title'] + ' | Alphastar Portal');
    forkJoin({
      parentAdvisors: this.agentsApiService.getParentAgents(false),
      selfAdvisor: this.agentsApiService.getAgentSelf()
    }).subscribe({
      next: result => {
        this.advisors = this.initAdvisors(result.selfAdvisor, result.parentAdvisors);
        if (this.advisors.length > this.isArcAdvisorLimit) this.isArcAdvisor = true;

        this.initSelectAdvisor(result.selfAdvisor, result.parentAdvisors);

        // if 50+ advisors, pass initial query with first advisor but pass it as a list, else pass all advisors
        if (this.isArcAdvisor) {
          const advisor = this.advisors.find(a => a.agentID === this.selectedRadioAdvisorId);
          this.advisors = [advisor as AgentModel];
        }

        this.requestsApiService.getRequestsByAgentIds(this.advisors.map(a => a.agentID)).subscribe({
          next: result => {
            this.requests = result;

            // Remove Orion Service Request as option type if no reqyests with that category exist yet
            const hasOrionRequests = this.requests.some(r => r.category === RequestTrackerTypeEnum.OrionServiceRequest);
            if (!hasOrionRequests) {
              this.tableFilter.Types = this.tableFilter.Types.filter(t => t.Name !== RequestTrackerTypeEnum.OrionServiceRequest);
            }

            this.filterRequests();

          }, error: () => {
            this.snackbarService.openSnackbar('Error retrieving requests', ToastClassEnum.warning);
          }
        }).add(() => {
          //check for open requests, if any exist, re-filter the requests with status of 'Open'
          const hasOpenRequests = this.filteredRequests.some(r => r.status.includes('Received') || r.status.includes('In Process') || r.status.includes('Action') || r.status.includes('Settlement'));
          if (hasOpenRequests) {
            this.tableFilter.Status = 'Open';
            this.filterRequests();
          } else this.tableFilter.Status = 'All';
        });
      },
      error: () => {
        this.snackbarService.openSnackbar('Error retrieving Data.', ToastClassEnum.warning);
      },
    }).add(() => {
      this.isLoading = false;
      if (this.requestList) this.requestList.isLoading = false;
    });
  }

  compareAdvisors(a: AgentModel, b: AgentModel): boolean {
    return a && b && a.agentID === b.agentID;
  }

  /**Use radio buttons when selecting from 50+ advisors to prevent excessive load time
   * and allow picking one advisor at a time.
   * @param filteredAdvisorId model of selected advisor
  */
  radioAdvisorChecked(filteredAdvisorId: string | number): void {

    this.selectRadioOption(filteredAdvisorId);
    this.loadSingleAdvisorRequests();
  }

  selectRadioOption(filteredAdvisorId: string | number): void {
    if (this.filteredAdvisors.some(a => a.Value === this.selectedRadioAdvisorId)) {
      const oldSelectedRadioAdvisor = this.filteredAdvisors.find(a => a.Value === this.selectedRadioAdvisorId) as FilterCheckbox;
      oldSelectedRadioAdvisor.Checked = false;
    }

    this.selectedRadioAdvisorId = Number(filteredAdvisorId);
    const newSelectedRadioAdvisor = this.filteredAdvisors.find(a => a.Value === this.selectedRadioAdvisorId) as FilterCheckbox;
    newSelectedRadioAdvisor.Checked = true;
  }

  loadSingleAdvisorRequests(): void {
    this.requestsApiService.getRequestsByAgentIds([this.selectedRadioAdvisorId]).subscribe({
      next: result => {
        this.requests = result;
        this.filterRequests();
      }, error: () => {
        this.snackbarService.openSnackbar('Error retrieving requests', ToastClassEnum.warning);
      }
    });
  }

  filterRequests(): void {
    const activeAgentIdFilters: FilterCheckbox[] = this.tableFilter.Advisors.filter(t => t.Checked);

    //save selected advisorIds to local storage
    const selectedAdvisorIds = activeAgentIdFilters.map(adv => adv.Value) as [];
    this.savedFiltersService.setSelectedAdvisors(selectedAdvisorIds);

    const activeTypeFilters: FilterCheckbox[] = this.tableFilter.Types.filter(t => t.Checked);
    this.filteredRequests = this.requests.filter(r => {
      const accountAgentIds = r.advisors.map(adv => adv.agentID);
      const accountContainsAgent = accountAgentIds?.some(id => activeAgentIdFilters.some(a => a.Value === id));
      if (!accountContainsAgent || (activeTypeFilters.length && !activeTypeFilters.some(t => t.Value === r.category))) {
        return false;
      }
      this.filterStatus = this.tableFilter.Status;

      if (this.tableFilter.Status !== '' && this.tableFilter.Status !== 'All') {
        /* 'Open' filters list to Submitted/Pending requests; 'Closed' filters list to Completed/Cancelled requests*/
        if (this.tableFilter.Status === 'Open') {
          this.sortDirection = 'desc';
          return (r.status.includes('Received') || r.status.includes('In Process') || r.status.includes('Action') || r.status.includes('Settlement')) && hasAll(r, this.searchTerm);
        }

        if (this.tableFilter.Status === 'Closed') {
          this.sortDirection = 'desc';
          return (r.status.includes('Completed') || r.status.includes('Rejected')) && hasAll(r, this.searchTerm);
        }
      }
      this.sortDirection = 'desc';
      return hasAll(r, this.searchTerm);
    });
  }

  getAdvisors(): Observable<{ parentAdvisors: AgentModel[]; selfAdvisor: AgentModel | null; }> {
    return forkJoin({
      parentAdvisors: this.agentsApiService.getParentAgents(false),
      selfAdvisor: this.agentsApiService.getAgentSelf()
    });
  }

  initAdvisors(selfAdvisor: AgentModel | null, parentAdvisors: AgentModel[]): AgentModel[] {
    // combine parent advisors and self advisor
    let advisors: AgentModel[] = parentAdvisors;
    if (selfAdvisor !== null) {
      advisors = parentAdvisors.concat([selfAdvisor]);
    }
    return advisors;
  }

  initSelectAdvisor(selfAdvisor: AgentModel | null, parentAdvisors: AgentModel[]): void {

    // for preselect parent and self advisors if the user hasn't any saved in local storage yet
    const preSelectedAdvisors = parentAdvisors.concat([selfAdvisor as AgentModel]);
    const savedAdvisors = this.savedFiltersService.getSelectedAdvisors();

    parentAdvisors.forEach(a => {
      this.tableFilter.Advisors.push({
        Name: a.firstName + ' ' + a.lastName,
        Value: a.agentID,
        Checked: this.isArcAdvisor ? false : savedAdvisors.length > 0 ? savedAdvisors.some(x => x === a.agentID) : preSelectedAdvisors.some(x => x.agentID === a.agentID),
      });
    });
    if (selfAdvisor) {
      this.tableFilter.Advisors.push({
        Name: selfAdvisor.firstName + ' ' + selfAdvisor.lastName,
        Value: selfAdvisor.agentID,
        Checked: this.isArcAdvisor ? false : savedAdvisors.length > 0 ? savedAdvisors.some(a => a === selfAdvisor.agentID) : preSelectedAdvisors.some(a => a.agentID === selfAdvisor.agentID),
      });
    }

    this.selectedRadioAdvisorId = this.tableFilter.Advisors[0].Value as number;
    this.filterAdvisorList();

    //set index for radio list if this.isArcAdvisor is true
    if (this.isArcAdvisor === true) {
      //load the the first saved advisorId if any else load the first advisor
      const agentId = savedAdvisors.length > 0 ? savedAdvisors[0] : this.tableFilter.Advisors[0].Value;
      this.selectRadioOption(agentId);
    }
  }

  filterAdvisorList(): void {
    this.filteredAdvisors = this.tableFilter.Advisors.filter(a => {
      return hasAll(a, this.advisorSearchTerm);
    });
  }
}