@if (complete === true) {
  <div class="flex flex-col items-center justify-center self-center w-full gap-y-30 min-h-500">
    <span class="material-icons mi-96 text-green-dark">task_alt</span>
    <label class="font-bold">Thank You!</label>
    <label class="font-semibold">Your {{requestTypeName}} request has been submitted successfully.</label>
    <button type="button" class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
    (click)="closeRequest()">Close</button>
  </div>
} @else {
  <div class="flex flex-col items-center justify-center self-center w-full gap-y-30 min-h-300">
    <span class="material-icons mi-96 text-red-dark">error</span>
    <label class="font-bold">Uh Oh!</label>
    <label class="font-semibold">Something went wrong with submitting your {{requestTypeName}} request.</label>
    <label class="font-semibold">Please Try submitting again.</label>
  </div>
}
