import { Component, AfterViewInit, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialogRef } from '@angular/material/dialog';
import { catchError, debounceTime, distinctUntilChanged, forkJoin, map, Observable, startWith } from 'rxjs';
import { AgentModel, AgentsClient, ClientModel, ClientsClient, CustodianModel, CustodiansClient, FileParameter, InvestmentAccountsClient, InvestmentAccountTypeModel, InvestmentModel, InvestmentModelsClient, PaperUploadAccountModel, PaperUploadClientModel } from 'src/app/core/clients/generated/client';
import { ToastClassEnum } from 'src/app/core/services/snackbar/snackbar.models';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { fadeIn } from 'src/app/shared/constants/animations';
import { getFormValue, FormModel } from 'src/app/shared/helpers/form.helpers';
import { search } from 'src/app/shared/helpers/search.helpers';
import { PaperApplicationClientForm, PaperApplicationForm } from '../../models/account.models';

@Component({
  selector: 'app-paper-app-modal',
  templateUrl: './paper-app-modal.component.html',
  animations: [ fadeIn ]
})
export class PaperAppModalComponent implements OnInit, AfterViewInit {
  constructor (
    private formBuilder: UntypedFormBuilder,
    private agentsApiService: AgentsClient,
    private clientsApiService: ClientsClient,
    private custodianApiService: CustodiansClient,
    private investmentAccountsApiService: InvestmentAccountsClient,
    private investmentModelApiService: InvestmentModelsClient,
    private snackbarService: SnackbarService,
    public dialogRef: MatDialogRef<PaperAppModalComponent>,
  ) { }
  iars: AgentModel[] = [];
  custodians: CustodianModel[] = [];
  clients: ClientModel[] = [];
  filteredClients?: Observable<ClientModel[]>;
  newClient: ClientModel = { clientID: 0, personID: 0, firstName: 'Add New Client', middleName: '', lastName: '', nickName: '' };
  investmentModels: InvestmentModel[] = [];
  investmentAccountTypes: InvestmentAccountTypeModel[] = [];
  paperApplicationForm: UntypedFormGroup = this.buildForm();
  instruction = 'To submit paperwork for account opening to Alphastar for processing, please complete the information below and upload paperwork.';
  isLoading = false;
  isSubmitting = false;
  isSubmitted = false;
  isComplete = false;
  submitCount = 0;

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit(): void {
    this.updateDialogDisplay();
  }

  closeRequest(): void {
    this.dialogRef.close(false);
  }

  getData(): void {
    this.isLoading = true;
    forkJoin({
      iars: this.agentsApiService.getParentAgents(true).pipe(catchError(error => {
        throw error;
      })),
      custodians: this.custodianApiService.getCustodians().pipe(catchError(error => {
        throw error;
      })),

      investmentAccountTypes: this.investmentAccountsApiService.getInvestmentAccountTypes().pipe(catchError(error => {
        throw error;
      }))
    })
      .subscribe({
        next: result => {
          this.iars = result.iars.sort((a, b) => {
            return (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0);
          });
          this.custodians = result.custodians.sort((a, b) => {
            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
          });
          this.investmentAccountTypes = result.investmentAccountTypes.sort((a, b) => {
            return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
          });
        },
        error: () => {
          this.snackbarService.openSnackbar('Error retrieving Form Data.', ToastClassEnum.warning);
        },
      }).add(() => this.isLoading = false);
  }

  updateDialogDisplay(): void {
    const width = '60rem';
    const height = '82rem';
    this.dialogRef.updateSize(width, height);
  }

  displayClient(client?: ClientModel): string {
    if (client)
      return client.firstName + ' ' + client.lastName;
    else return '';
  }

  filterClients(searchString: string): ClientModel[] {
    return search(this.clients, searchString, [ 'firstName', 'lastName' ]);
  }

  onChangeIars(): void {
    this.paperApplicationForm.controls['InvestmentAccountModelID'].reset();
    const iarId = getFormValue<number>(this.paperApplicationForm, 'Iar');
    forkJoin({
      clients: this.clientsApiService.getAgentClients(iarId),
      models: this.investmentModelApiService.getAgentInvestmentModels([iarId])
    }).subscribe({
      next: results => {
        this.investmentModels = results.models;
        this.clients = results.clients;
        this.filteredClients = this.paperApplicationForm.get('Client')?.valueChanges.pipe(
          startWith(this.clients[ 0 ]),
          debounceTime(200),
          distinctUntilChanged(),
          map((searchTerm: string) => this.filterClients(searchTerm).sort((a, b) => a.lastName < b.lastName ? -1 : a.lastName > b.lastName ? 1 : 0)));
      },
      error: () => {
        this.snackbarService.openSnackbar('Error retrieving Clients.', ToastClassEnum.warning);
      }
    });
  }

  onClientChange(event: MatAutocompleteSelectedEvent): void {
    if ((event.option.value as ClientModel) === this.newClient) {
      this.paperApplicationForm.addControl('NewClient', this.buildClientGroup());
    } else {
      if (this.paperApplicationForm.get('NewClient')) {
        this.paperApplicationForm.removeControl('NewClient');
      }
    }
  }

  onSubmit(): void {
    if (this.paperApplicationForm.invalid) {
      this.paperApplicationForm.markAllAsTouched();
      return;
    }
    this.isSubmitting = true;
    this.paperApplicationForm.disable();
    const form: PaperApplicationForm = this.paperApplicationForm.value as PaperApplicationForm;
    const agentID: number = form.Iar as number;
    if (form.Files && form.Files.length && form.InvestmentAccountModelID && form.InvestmentAccountTypeID && form.CustodianID) {
      const files: FileParameter[] = form.Files.map(file => {
        return { data: file, fileName: file.name };
      });

      const account: PaperUploadAccountModel = {
        investmentAccountModelID: form.InvestmentAccountModelID,
        investmentAccountTypeID: form.InvestmentAccountTypeID,
        custodianID: form.CustodianID,
        clientID: form.Client?.clientID === 0 ? undefined : form.Client?.clientID,
        note: form.Notes ?? ''
      };

      let client: PaperUploadClientModel | null = null;
      const clientData = getFormValue<PaperApplicationClientForm>(this.paperApplicationForm, 'NewClient');
      if (clientData && clientData.FirstName && clientData.LastName && clientData.Email) {
        client = {
          firstName: clientData.FirstName,
          lastName: clientData.LastName,
          email: clientData.Email,
          agentID
        };
      }

      this.investmentAccountsApiService.createInvestmentAccount(files, client, account, agentID).subscribe({
        error: () => {
          this.submitCount++;
          // for work around on sometimes the first request failing submitting to the crm.
          if (this.submitCount < 2){
            console.log('received error, retrying in 2 seconds');
            setTimeout(() =>{
              this.onSubmit();
            }, 2000);
          } else {
            this.isSubmitting = false;
            this.isSubmitted = true;
            this.isComplete = false;
            this.submitCount = 0;
          }
        },
        complete: () => {
          this.isSubmitting = false;
          this.isSubmitted = true;
          this.isComplete = true;
        }
      });
    }
  }

  uploadFiles(files: File[]): void {
    this.paperApplicationForm.get('Files')?.setValue(files);
  }

  private buildForm(): UntypedFormGroup {
    const form: FormModel<PaperApplicationForm> = {
      Iar: [ null, Validators.required ],
      CustodianID: [ null, Validators.required ],
      InvestmentAccountModelID: [ null, Validators.required ],
      InvestmentAccountTypeID: [ null, Validators.required ],
      Client: [ null, Validators.required ],
      Notes: [ null ],
      Files: [ null, Validators.required ]
    };
    return this.formBuilder.group(form);
  }

  private buildClientGroup(): UntypedFormGroup {
    const form: FormModel<PaperApplicationClientForm> = {
      FirstName: [ null, Validators.required ],
      LastName: [ null, Validators.required ],
      Email: [ null, Validators.compose([ Validators.required, Validators.email ]) ]
    };
    return this.formBuilder.group(form);
  }
}