import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxCurrencyConfig } from 'ngx-currency';
import { Subscription } from 'rxjs';
import { CustomCurrencyMaskConfig } from 'src/app/shared/config/currency-mask.config';
import { AccountTypeList, AnalysisReportTypeList } from '../../constants/planning-desk-request.constant';
import { AssetEntryFormGroup, PlanningDeskAssetDetailsFormGroup } from '../../models/planning-desk.model';
import { GenericRequestOption } from '../../../shared/models/workflow.models';
import { AnalysisReportTypeEnum } from '../../enums/analysis-report-type.enum';

@Component({
  selector: 'app-asset-details-step',
  templateUrl: './asset-details-step.component.html',
})
export class AssetDetailsStepComponent implements OnInit, OnDestroy {
  @Input() parentForm?: PlanningDeskAssetDetailsFormGroup;
  analysisReportTypeList = AnalysisReportTypeList;
  files: File[] | undefined;
  accountTypeList: GenericRequestOption[] = [];
  clientFeeMaskOptions: NgxCurrencyConfig = {
    ...CustomCurrencyMaskConfig, ...{
      precision: 2,
      prefix: '',
      suffix: '%',
      min: 0,
      max: 100
    }
  };
  private analysisReportTypeSubscription?: Subscription;

  constructor (
    private fb: UntypedFormBuilder
  ) { }

  private compare(a: GenericRequestOption, b: GenericRequestOption): number {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  }

  newAssetEntryFormGroup(): AssetEntryFormGroup {
    return this.fb.group({
      accountType: [ null, [ Validators.required ] ],
      currentValue: [ null, [ Validators.required, Validators.pattern('') ] ],
      clientFee: [ null, [ Validators.required, Validators.pattern('') ] ]
    }) as AssetEntryFormGroup;
  }

  addNewAssetEntry(): void {
    this.parentForm?.controls.assetBreakdown.push(this.newAssetEntryFormGroup());
  }

  removeAssetEntry(index: number): void {
    this.parentForm?.controls.assetBreakdown.removeAt(index);
  }

  toggleOtherReportTypeControl(): void {
    if (!this.parentForm?.controls.analysisReportTypeOther.value) {
      this.parentForm?.controls.analysisReportTypeOther.disable();
    }

    this.analysisReportTypeSubscription = this.parentForm?.controls.analysisReportType.valueChanges
      .subscribe((value: GenericRequestOption[]) => {
        const other = value.find(x => x.value === AnalysisReportTypeEnum.other);
        if (other) this.parentForm?.controls.analysisReportTypeOther.enable();
        else this.parentForm?.controls.analysisReportTypeOther.disable();
      });
  }

  uploadFiles(files: File[]): void {
    if (!this.parentForm) return;
    this.files = files;
    if (files.length) this.parentForm.controls.files.patchValue(files);
    else this.parentForm.controls.files.patchValue(null);
  }

  ngOnInit(): void {
    this.files = this.parentForm?.value.files;
    this.accountTypeList = AccountTypeList.sort(this.compare);
    this.toggleOtherReportTypeControl();
  }

  ngOnDestroy(): void {
    this.analysisReportTypeSubscription?.unsubscribe();
  }
}