<div class="flex flex-col h-full w-full items-center justify-center fixed z-10">
  <div class="flex flex-col items-center flex-shrink-0 w-full">
    <img class="w-256 m-auto mt-20 h-96 " src="assets/images/logos/alphastar-logo-light-bg.svg">
    <div class="relative flex-shrink-0 w-360 min-h-384 max-w-9/10 card">
      @if (!failure && !mfaSuccess) {
        @if (!passwordStepCompleted) {
          <app-password-reset-form class="h-full flex w-full overflow-auto" (formCompleted)="handlePasswordFormCompleted()"></app-password-reset-form>
        }
        @if (passwordStepCompleted) {
          <app-mfa-verification-form
            [context]="'password-reset'"
            [description]="'Before completing your password reset, please enter the 6-digit PIN sent to your primary SMS device registered to this account'"
            class="h-full flex w-full overflow-auto"
          (mfaSucceeded)="handleMfaResponse($event)"></app-mfa-verification-form>
        }
      }
      @if (failure && !mfaSuccess) {
        <div class="flex flex-col">
          <h4 class="text-2xl font-normal leading-tight text-center m-30">An error occurred</h4>
        </div>
        <div class="relative flex justify-center items-center my-30 mx-30 p-10 md:p-20 text-gray-dark text-lg  bg-blue-lightest rounded">
          <p>The password reset request associated with this link has {{failure}}. Click <a routerLink="/password-reset-request">here</a> to create a new request.</p>
        </div>
      }
      @if (mfaSuccess && !failure) {
        <div class="flex flex-col m-30">
          <h4 class="text-2xl font-normal leading-tight text-center">Success!</h4>
        </div>
        <div class="relative flex justify-center items-center my-30 mx-30 p-10 md:p-20 text-gray-dark text-lg  bg-blue-lightest rounded">
          <p>Your password has succesfully been reset! Click <a routerLink="/login">here</a> to log in.</p>
        </div>
      }
    </div>
    <app-copyright></app-copyright>
  </div>
</div>
<lottie-player @fadeIn class="fixed top-0 left-0 w-full h-auto z-0" src="assets/lottie/login-animate.json" background="transparent" loop autoplay>
</lottie-player>