@if (account && workflow) {
  <form @fadeIn [formGroup]="form" (ngSubmit)="onSubmit()" class="flex flex-col w-full h-full overflow-hidden">
    <app-account-request-modal-header #header [title]="workflow.title" [account]="account" [formService]="contributionFormService" (closeRequested)="closeRequest()"></app-account-request-modal-header>
    @if (isSubmitting) {
      <div class="flex-grow w-full loader-wrap" @fadeIn>
        <div class="loader-sm is-blue"></div>
      </div>
    }
    @if (!isSubmitting && !isSubmitted) {
      <section class="flex flex-col flex-grow h-full overflow-auto">
        @if (contributionFormService.currentStep === accountUpdateStepEnum.step1) {
          <app-contribute-funds [parentForm]="contributeFundsFormGroup"></app-contribute-funds>
        }
        @else if (contributionFormService.currentStep === accountUpdateStepEnum.step2) {
          <app-allocation-step class="h-full" [investmentRequestFormService]="contributionFormService" [parentForm]="allocationFormGroup" [initialAmount]="initialAmount" [requestType]="requestTypeEnum.contribution"></app-allocation-step>
        }
        @else if (contributionFormService.currentStep === accountUpdateStepEnum.step3) {
          <app-delivery-methods [parentForm]="deliveryOptionsFormGroup" [requestType]="requestTypeEnum.contribution"></app-delivery-methods>
        }
        @else if (contributionFormService.currentStep === accountUpdateStepEnum.step4) {
          <app-review-step [investmentRequestFormService]="contributionFormService" [requestType]="requestTypeEnum.contribution"></app-review-step>
        }
      </section>
    }
    @if (!isSubmitting && isSubmitted) {
      <section class="flex flex-col flex-grow h-full overflow-auto">
        <app-complete [complete]="isComplete"  [requestType]="requestTypeEnum.contribution"></app-complete>
      </section>
    }
    @if (!showCustomHoldings && !isComplete) {
      <footer>
        @if (contributionFormService.currentStep === accountUpdateStepEnum.step1) {
          <button [disabled]="isSubmitting" type="button" class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
          (click)="cancelRequest()">Back</button>
        }
        @if ((contributionFormService.currentStep > accountUpdateStepEnum.step1)) {
          <button [disabled]="isSubmitting" type="button" mat-button class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
          (click)="contributionFormService.goToPreviousStep(); isSubmitted=false;">Back</button>
        }
        @if ((contributionFormService.currentStep <= (pages.length - 1))) {
          <button [disabled]="isSubmitting" type="button" mat-button class="m-10 ml-auto button is-primary is-lg flex justify-center"
          (click)="contributionFormService.goToNextStep(pages[contributionFormService.currentStep - 1].formGroup, pages.length)">Next</button>
        }
        @if (contributionFormService.currentStep === accountUpdateStepEnum.step4) {
          <button [disabled]="isSubmitting" type="submit" class="m-10 ml-auto button is-primary is-lg flex justify-center">{{isSubmitting ? "Submitting" : "Submit"}}</button>
        }
      </footer>
    }
  </form>
}