<section class="flex flex-col w-full h-full gap-y-80">
  <section class="flex flex-col w-full p-20 mx-auto max-w-1440 gap-x-40 gap-y-40">
    <h2 class="text-2xl">Inputs</h2>
    <div class="flex">
      <button class="button" mat-button>Button</button>
      <mat-select class="select m-10">
        <mat-option value="1">Value 1</mat-option>
        <mat-option value="2">Value 2</mat-option>
        <mat-option value="3">Value 3</mat-option>
      </mat-select>
    </div>
    <div class="flex">
      <mat-checkbox>This is a checkbox</mat-checkbox>
    </div>
    <div class="flex flex-col gap-y-10">
      <label class="text-xl" id="radio-group-label">Radio Group</label>
      <mat-radio-group aria-labelledby="radio-group-label" class="flex flex-col gap-y-10">
        @for (item of sampleArray; track item) {
          <mat-radio-button class="example-radio-button" [value]="item">
            {{item.Name}}
          </mat-radio-button>
        }
      </mat-radio-group>
    </div>
    <div class="flex">
      <mat-slider min="1" max="10" step="1" value="3" class="w-300"></mat-slider>
    </div>
    <div class="flex flex-wrap gap-x-20 gap-y-20">
      <p class="w-full">We can reference icons from 2 sources: <a href="https://fonts.google.com/icons" target="_blank">Google Icon Font</a> and <a href="https://materialdesignicons.com/" target="_blank">Material Community Icons</a>. Usage seen below for both sources, respectively.</p>
      <mat-icon svgIcon="file-multiple"></mat-icon>
      <mat-icon>home</mat-icon>
    </div>
    <div class="flex">
      <mat-slide-toggle>Slide me</mat-slide-toggle>
    </div>
    <div class="flex flex-col w-400 gap-y-10">
      <ng-container  *appShowIfAll="perm.ViewComplianceCentralSso"><a mat-button class="flex items-center justify-center leading-tight button is-primary" href="api/sso/basis-code" target="_blank" rel="noopener">BasisCode Sso</a></ng-container>
      <ng-container  *appShowIfAll="perm.ViewOrionSso"><a mat-button class="flex items-center justify-center leading-tight button is-primary" href="api/sso/orion" target="_blank" rel="noopener">Orion Sso</a></ng-container>
      <ng-container  *appShowIfAll="perm.ViewEnvestnetSso"><a mat-button class="flex items-center justify-center leading-tight button is-primary" href="api/sso/envestnet" target="_blank" rel="noopener">Envestnet Sso</a></ng-container>
    </div>
    <h2 class="text-2xl">Permission Directive Testing</h2>
  </section>
  <section class="flex flex-col w-full p-20 mx-auto max-w-1440 gap-x-40 gap-y-40">
    <h2 class="text-2xl">Button</h2>
    <button class="button is-primary" mat-button (click)="openDeliveryMethod()">Open Delivery Options Page</button>
    <button class="button is-primary" mat-button (click)="openCustomHoldings()">Open Custom Holdings</button>
    <button class="button is-primary" mat-button (click)="openAllocations()">Open Allocation</button>
    <button class="button is-primary" mat-button (click)="openReviewPage()">Open Review Page</button>
    <button class="button is-primary" mat-button (click)="openContributeFundsPage()">Open Contribute Funds Page</button>
    <button class="button is-primary" mat-button (click)="openPlanningDeskRequest()">Open Planning Desk Request</button>
  </section>
  <section class="flex flex-col w-full p-20 mx-auto max-w-1440 gap-x-40 gap-y-40">
    <h2 class="text-2xl">Datepicker</h2>
    <div class="datepicker-input-container">
      <input [matDatepicker]="picker" class="datepicker-input" (click)="picker.open()" readonly
        placeholder="mm/dd/yyyy">
        <mat-icon class="datepicker-icon" svgIcon="calendar" matDatepickerToggleIcon></mat-icon>
        <mat-datepicker #picker color="accent"></mat-datepicker>
      </div>
    </section>
    <section class="grid w-full grid-cols-4 p-20 mx-auto max-w-1440 gap-x-40 gap-y-40">
      <h2 class="col-span-4 text-2xl">Lottie Animations</h2>
      <lottie-player class="col-span-4 w-256 h-256 md:col-span-1" src="https://assets4.lottiefiles.com/datafiles/zc3XRzudyWE36ZBJr7PIkkqq0PFIrIBgp4ojqShI/newAnimation.json" background="transparent" loop autoplay>
      </lottie-player>
      <lottie-player class="col-span-4 w-256 h-256 md:col-span-1" src="https://assets1.lottiefiles.com/datafiles/HN7OcWNnoqje6iXIiZdWzKxvLIbfeCGTmvXmEm1h/data.json" background="transparent" loop autoplay>
      </lottie-player>
      <lottie-player class="col-span-4 w-256 h-256 md:col-span-1" src="https://assets1.lottiefiles.com/packages/lf20_iwlmrnb5.json" background="transparent" loop autoplay>
      </lottie-player>
      <lottie-player class="col-span-4 w-full h-auto md:col-span-full" src="https://lottie.host/8113da0f-96c6-4b20-8a39-3232c5903751/CPE02D4nek.json" background="transparent" loop autoplay>
      </lottie-player>
    </section>
  </section>