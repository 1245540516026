<section>
  <div>
    <h4 class="leading-tight text-2xl font-normal text-center my-30">Account Registration</h4>
  </div>
  @if (failure) {
    <div
      class="flex justify-center items-center text-lg mx-32 my-10 p-10 md:p-20 bg-gray-lighter rounded text-gray-darker">
      @if (failure === 'expired') {
        <span class="text-center">This link has expired.</span>
      }
      @else if (failure === 'complete') {
        <span class="text-center">This registration link has already been completed.</span>
      }
    </div>
    <div class="mx-32 my-30 flex flex-col flex-none">
      <button mat-button class="button is-primary is-outlined focus:ring-0" (click)="login()"><span>Go to Login</span></button>
    </div>
  }
  @else{
    <form [formGroup]="signupForm" class="flex flex-col flex-auto" (submit)="onCompleteSubmit()">
      <div class="my-10 mx-32 gap-y-5 flex flex-shrink-0 flex-col">
        <label class="label required-label" for="email">Email</label>
        <input class="input disabled" type="email" readonly name="email" formControlName="email" [value]="registration?.emailAddress"/>
      </div>
      <div class="my-10 mx-32 gap-y-5 flex flex-shrink-0 flex-col">
        <label class="label required-label" for="password">Password <span class="text-red">*</span></label>
        <input class="input" type="password" name="password" formControlName="password"
          [disabled]="isDisabled || isSubmitting">
        @if (signupForm.controls['password'].touched && signupForm.controls['password'].errors) {
          <span class="w-full text-xs text-red"
            >
            @if (signupForm.controls['password'].hasError('required')) {
              <span>This field is required.</span>
            }
            @if (signupForm.controls['password'].hasError('minlength')) {
              <span>Password must be at least 8
              characters.</span>
            }
            @if (signupForm.controls['password'].hasError('maxlength')) {
              <span>Password must be fewer than 50
              characters.</span>
            }
          </span>
        }
      </div>
      <div class="my-10 mx-32 gap-y-5 flex flex-shrink-0 flex-col">
        <label class="label required-label" for="passwordConfirmation">Confirm Password <span
        class="text-red">*</span></label>
        <input class="input" type="password" name="passwordConfirmation" formControlName="passwordConfirmation"
          [disabled]="isDisabled || isSubmitting">
        @if (signupForm.controls['passwordConfirmation'].touched && signupForm.controls['passwordConfirmation'].value !==  signupForm.controls['password'].value) {
          <span class="w-full text-xs text-red">
            @if (signupForm.controls['passwordConfirmation'].hasError('required') || signupForm.controls['passwordConfirmation'].value !==  signupForm.controls['password'].value) {
              <span
                >Passwords
              must match.</span>
            }
          </span>
        }
      </div>
      <div class="mx-32 my-30 gap-y-5 flex flex-col flex-none">
        <button mat-button class="button is-primary is-outlined focus:ring-0" type="submit"
          (click)="isSubmitting = true"
          [disabled]="isDisabled || !signupForm.valid || signupForm.controls['passwordConfirmation'].value !==  signupForm.controls['password'].value">
          Sign{{isSubmitting ? 'ing' : ''}} up
        </button>
      </div>
      @if (isDisabled) {
        <div class="text-md text-red font-normal text-center mx-48 mb-10">
          This account has been disabled.
        </div>
      }
    </form>
  }
</section>