import { Component } from '@angular/core';

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
})
export class CopyrightComponent {
  copyYear = this.getYear();

  getYear(): number {
    return new Date().getFullYear();
  }
}
