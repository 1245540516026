@if (requestType) {
  <section class="flex flex-col flex-grow overflow-auto p-30 gap-y-20 gap-x-20">
    <p class="mb-10 text-lg">Please review your request below and make sure all the information is accurate.</p>
    <!--Contribution-->
    @if (requestType === requestTypeEnum.contribution) {
      <div class="flex flex-row justify-between w-full gap-x-10">
        <span class="font-bold">Gross Contribution Amount</span>
        <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
        <span class="font-bold text-blue-dark">{{(reviewModel?.amount || 0) | currency}}</span>
      </div>
      <span class="flex-none divider-horizontal"></span>
      <div class="flex flex-col gap-y-10">
        @for (item of reviewModel?.sleeves; track item) {
          <div class="flex flex-row justify-between w-full gap-x-10">
            <span>{{item.name || 'No Current Model'}}</span>
            <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
            <span class="font-bold text-gray-dark">{{(item?.amount || 0) | currency}} ({{(item.percent |
            percent:'1.0-2')}})</span>
          </div>
        }
        @if (reviewModel?.protectedCash) {
          <div class="flex flex-row justify-between w-full gap-x-10">
            <span>Cash</span>
            <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
            <span class="font-bold text-gray-dark">{{(reviewModel?.protectedCash?.amount || 0) | currency}}
            (Client Fee: {{reviewModel?.protectedCash?.fee}}%)</span>
          </div>
        }
        @if (reviewModel?.customHoldings) {
          <div class="flex flex-col gap-y-10">
            <div class="flex flex-row justify-between w-full gap-x-10">
              <span>Custom Holdings</span>
              <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
              <span class="font-bold text-gray-dark">{{(reviewModel?.customHoldings?.amount || 0) | currency}}
              ({{(reviewModel?.customHoldings?.percent | percent:'1.0-2')}})</span>
            </div>
            @for (item of reviewModel?.customHoldings?.holdings; track item) {
              <div
                class="flex flex-row justify-between w-full pl-20 text-sm gap-x-10">
                <span>{{item.name}}</span>
                <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                <span class="font-bold text-green">{{item.type}} {{item.amount ? ((item.amount || 0) | currency)
                : (item.shareCount || '') + ' Shares'}}</span>
              </div>
            }
            @if (reviewModel?.customHoldings?.notes?.length) {
              <div class="flex flex-col gap-y-2 text-sm pl-20">
                <span>Trade Instructions:</span>
                <div class="flex flex-col p-5 rounded md:p-5 bg-gray-lightest">
                  <span
                  class="whitespace-pre-wrap overflow-wrap-text text-gray-dark">{{reviewModel?.customHoldings?.notes}}</span>
                </div>
              </div>
            }
          </div>
        }
      </div>
    }
    <!--Distribution-->
    @if (requestType === requestTypeEnum.distribution) {
      <div class="flex flex-col gap-y-10">
        <div class="flex flex-row justify-between w-full gap-x-10">
          <span>Gross Distribution Amount</span>
          <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
          <span class="font-bold text-gray-dark">{{(reviewModel?.amount || 0) | currency}}</span>
        </div>
        @if (reviewModel?.taxWithholding) {
          <div class="flex flex-row justify-between w-full gap-x-10">
            <span>Federal Tax Withheld</span>
            <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
            <span class="font-bold text-gray-dark">{{(reviewModel?.taxWithholding?.federalPercent ||
            0)}}%</span>
          </div>
          <div class="flex flex-row justify-between w-full gap-x-10">
            <span>State Tax Withheld</span>
            <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
            <span class="font-bold text-gray-dark">{{(reviewModel?.taxWithholding?.statePercent ||
            0)}}%</span>
          </div>
          <div class="flex flex-row justify-between w-full gap-x-10">
            <span class="font-bold">Amount Client Will Receive</span>
            <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
            <span class="font-bold text-blue-dark">{{(reviewModel?.taxWithholding?.receiveAmount || 0) |
            currency}}</span>
          </div>
        }
      </div>
      <span class="flex-none divider-horizontal"></span>
      <div class="flex flex-col gap-y-10">
        @for (item of reviewModel?.sleeves; track item) {
          <div class="flex flex-row justify-between w-full gap-x-10">
            <span>{{item.name || 'No Current Model'}}</span>
            <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
            <span class="font-bold text-gray-dark">{{(item?.amount || 0) | currency}}</span>
          </div>
        }
        @if (reviewModel?.customHoldings) {
          <div class="flex flex-col gap-y-10">
            <div class="flex flex-row justify-between w-full gap-x-10">
              <span>Custom Holdings</span>
              <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
              <span class="font-bold text-gray-dark">{{(reviewModel?.customHoldings?.amount || 0) |
              currency}}</span>
            </div>
            @for (item of reviewModel?.customHoldings?.holdings; track item) {
              <div
                class="flex flex-row justify-between w-full pl-20 text-sm gap-x-10">
                <span>{{item.name}}</span>
                <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                <span class="font-bold text-orange">{{item.type}} {{item.amount ? ((item.amount || 0) |
                currency) : (item.shareCount || '') + ' Shares'}}</span>
              </div>
            }
            @if (reviewModel?.customHoldings?.notes?.length) {
              <div class="flex flex-col gap-y-4 text-sm pl-20">
                <span>Trade Instructions:</span>
                <div class="flex flex-col p-10 rounded md:p-10 bg-gray-lightest">
                  <span
                  class="mb-10 whitespace-pre-wrap overflow-wrap-text text-gray-dark">{{reviewModel?.customHoldings?.notes}}</span>
                </div>
              </div>
            }
          </div>
        }
      </div>
    }
    <!--Reallocation-->
    @if (requestType === requestTypeEnum.reallocation) {
      @if (!reviewModel?.isTradesOnly) {
        <div class="flex flex-row justify-between w-full gap-x-10">
          <span class="font-bold">Total Funds</span>
          <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
          <span class="font-bold text-blue-dark">{{(reviewModel?.amount || 0) | currency}}</span>
        </div>
      }
      @if (!reviewModel?.isTradesOnly) {
        <span class="flex-none divider-horizontal"></span>
      }
      <div class="flex flex-col gap-y-10">
        @for (item of reviewModel?.sleeves; track item) {
          <div class="flex flex-row justify-between w-full gap-x-10">
            <span>{{item.name || 'No Current Model'}}</span>
            <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
            <span class="font-bold text-gray-dark">{{(item?.accountTotalFee || 0).toFixed(3) }}%</span>
            <span class="font-bold text-gray-dark">{{(item?.amount || 0) | currency}} ({{(item.percent |
            percent:'1.0-2')}})</span>
          </div>
        }
        @if (reviewModel?.customHoldings) {
          <div class="flex flex-col gap-y-10">
            <div class="flex flex-row justify-between w-full gap-x-10">
              <span>Custom Holdings</span>
              <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
              @if (!reviewModel?.isTradesOnly) {
                <span class="font-bold text-gray-dark">{{(reviewModel?.customHoldings?.accountTotalFee ||
                0).toFixed(3) }}%</span>
              }
              @if (!reviewModel?.isTradesOnly) {
                <span class="font-bold text-gray-dark">{{(reviewModel?.customHoldings?.amount || 0) | currency}}
                ({{(reviewModel?.customHoldings?.percent | percent:'1.0-2')}})</span>
              }
            </div>
            @for (item of reviewModel?.customHoldings?.holdings; track item) {
              <div
                class="flex flex-row justify-between w-full pl-20 text-sm gap-x-10">
                <span>{{item.name}}</span>
                <span class="flex-grow border-b border-dotted border-gray-lighter"></span>
                <span class="font-bold"
                  [ngClass]="item.type === 'Buy' ? 'text-green' : item.type === 'Sell' ? 'text-orange' : ''">{{item.type}}
                  {{item.amount ? ((item.amount || 0) | currency) : (item.shareCount || '') + '
                Shares'}}</span>
              </div>
            }
            @if (reviewModel?.customHoldings?.notes?.length) {
              <div class="flex flex-col gap-y-2 text-sm pl-20">
                <span>Trade Instructions:</span>
                <div class="flex flex-col p-5 rounded md:p-5 bg-gray-lightest">
                  <span
                  class="whitespace-pre-wrap overflow-wrap-text text-gray-dark">{{reviewModel?.customHoldings?.notes}}</span>
                </div>
              </div>
            }
          </div>
        }
      </div>
      <span class="flex-none mb-5 divider-horizontal"></span>
    }
    @if (reviewModel?.delivery) {
      <div
        class="flex flex-row justify-between mt-10 mb-5 border-t border-b full-w py-30 border-gray-lighter">
        <div class="flex flex-col">
          <span>Method:</span><span class="font-bold text-gray-dark">{{reviewModel?.delivery?.method}}
          {{reviewModel?.delivery?.methodEnum === deliveryMethodEnum.journalCash ? '(' +
          reviewModel?.delivery?.journalAccountNumber +')' : reviewModel?.delivery?.methodEnum ===
          deliveryMethodEnum.wireTransfer ? '(' + reviewModel?.delivery?.wireTransferTo +')' : ''}}
          </span>
        </div>
        <div class="flex flex-col"><span>Frequency:</span><span
        class="font-bold text-gray-dark">{{reviewModel?.delivery?.frequency}}</span></div>
        <div class="flex flex-col pl-5"><span>Begins on:</span><span
        class="font-bold text-gray-dark">{{(reviewModel?.delivery?.startDate | date: 'MM/dd/yyyy') ||
        'N/A'}}</span></div>
      </div>
    }
    <div class="flex flex-col gap-y-10">
      <span>Notes:</span>
      @if (reviewModel?.notes?.length) {
        <div class="flex flex-col p-10 rounded md:p-20 bg-gray-lightest">
          <span class="mb-10 whitespace-pre-wrap overflow-wrap-text text-gray-dark">{{reviewModel?.notes}}</span>
        </div>
      } @else{
        <em class="text-sm text-gray-light">No notes</em>
      }
    </div>
    <div class="flex flex-col">
      <span>Files:</span>
      @for (item of reviewModel?.files; track item) {
        <div class="flex flex-col gap-y-5" class="font-bold">
          {{item.name}}
        </div>
      }
    </div>
  </section>
}