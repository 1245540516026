@if (parentForm) {
  <div class="flex flex-col w-full">
    <div class="flex flex-col overflow-auto p-30 gap-y-30">
      <div class="flex flex-col gap-y-30" [formGroup]="parentForm">
        <div class="flex flex-col">
          <span class="text-lg text-gray-dark">{{instruction}}</span>
        </div>
        <div class="flex flex-col gap-y-20">
          <label class="text-base font-bold text-gray-dark required-label" for="contributionAmount">How much would you like to contribute?</label>
          <input autocomplete="off" currencyMask [options]="contributionMaskOptions" type="text" class="w-full input" formControlName="contributionAmount" placeholder="$0.00" autofocus>
          @if (parentForm.controls.contributionAmount.touched && parentForm.controls.contributionAmount.errors ) {
            <span
              class="w-full text-xs -mt-15 text-red">
              @if (parentForm.controls.contributionAmount.errors['required']) {
                <span>This field is
                required.</span>
              }
              @if (parentForm.controls.contributionAmount.errors['pattern']) {
                <span>Please use a valid currency format (i.e., "$1,000.00")</span>
              }
            </span>
          }
        </div>
        <!--<app-solicitation-options [solicitationFormGroup]="parentForm"></app-solicitation-options>-->
      </div>
    </div>
  </div>
}