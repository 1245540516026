<header
  class="fixed top-0 left-0 z-50 flex flex-row items-center justify-start w-full px-10 bg-white border-solid shadow h-70 dark:bg-gray-darkest dark:shadow-none dark:border-b dark:border-gray-dark lg:px-20"
  @fadeIn>
  <div class="flex gap-x-20 sm:hidden items-center">
    <button class="icon-button is-primary is-inverted" mat-button (click)="acmPortalSidenav.toggle()">
      <mat-icon svgIcon="menu"></mat-icon>
    </button>
    <img class="block w-auto h-36" src="assets/images/logos/alphastar-logo-light-bg.svg"
      *appHideIfOne="perm.ViewEvolvExperience" alt="Alphastar Portal">
    <img class="block w-auto h-50" src="assets/images/logos/evolv-logo-long.png"
      *appShowIfOne="perm.ViewEvolvExperience" alt="Evolv">

  </div>
  @if (!loading) {
    <a class="items-start justify-center hidden m-10 mr-auto uppercase sm:flex max-w-256 hover:no-underline focus:no-underline text-ferra"
      routerLink="/" aria-label="Dashboard">
      <img class="hidden w-auto sm:block h-36" src="assets/images/logos/alphastar-logo-light-bg.svg"
        *appHideIfOne="perm.ViewEvolvExperience" alt="Alphastar Portal">
      <img class="hidden w-auto sm:block h-50" src="assets/images/logos/evolv-logo-long.png"
        *appShowIfOne="perm.ViewEvolvExperience" alt="Evolv">
    </a>
  }
  <ng-container
    *appShowIfOne="[perm.SubmitContributionRequest, perm.SubmitDistributionRequest, perm.SubmitReallocationRequest, perm.SubmitAccountCloseRequest, perm.SubmitPlanningDeskRequest, perm.SubmitMessageFile, perm.SubmitNewPaperAccount]">
    <div class="items-center justify-center hidden my-10 ml-auto xs:flex">
      <ng-container>
        <button class="mr-20 button is-primary" mat-button [matMenuTriggerFor]="quickLinksMenu">
          <span class="flex items-center justify-center">
            <span class="ml-5">Quick Links</span><i class="fig-18px fig-triangle-down-filled"></i>
          </span>
        </button>
      </ng-container>
      <mat-menu #quickLinksMenu="matMenu">
        <div class="flex flex-col items-start gap-y-10">
          <ng-container *appHideIfOne="perm.ViewOrionElementProposals">
            <ng-container *appShowIfOne="[perm.ViewSchwabAdvisorCenterSso, perm.SubmitNewPaperAccount]">
              <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
                aria-label="Open TDA Institutional Account" [matMenuTriggerFor]="openAccountMenu">
                <span>Open an Account</span>
              </button>
              <mat-menu #openAccountMenu="matMenu">
                <div class="flex flex-col items-start gap-y-10">
                  <ng-container *appShowIfOne="perm.ViewSchwabAdvisorCenterSso">
                    <a mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
                      aria-label="Open Schwab Account Opening" href="api/sso/schwab?destination=F_APPS" target="_blank">
                      <span>Launch Schwab Account Opening</span>
                    </a>
                  </ng-container>
                  <ng-container *appShowIfOne="perm.SubmitNewPaperAccount">
                    <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
                      aria-label="Open paper application" (click)="openPaperAccountModal()">
                      <span>Paper Application</span>
                    </button>
                  </ng-container>
                </div>
              </mat-menu>
            </ng-container>
          </ng-container>
          <ng-container *appShowIfOne="perm.ViewOrionElementProposals">
            <a mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
              (click)="openOrionAgentSelectModal('ap_proposals')"
              aria-label="Orion Advisor Portal - Account Opening">
              <span>Open an Account</span>
            </a>
          </ng-container>
          <ng-container *appShowIfOne="[perm.ViewOrionElementRequests]">
            <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
              aria-label="Submit New Request" [matMenuTriggerFor]="openOrionMenu">
              <span>Submit Service Request</span>
            </button>
            <mat-menu #openOrionMenu="matMenu">
              <div class="flex flex-col items-start gap-y-10">

                <ng-container>
                  <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
                    (click)="openOrionAgentSelectModal('ap_requests')" aria-label="Orion Advisor Portal - Contribution">
                    <span>Contribution</span>
                  </button>
                </ng-container>
                <ng-container>
                  <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
                    (click)="openOrionAgentSelectModal('ap_requests')" aria-label="Orion Advisor Portal - Distribution">
                    <span>Distribution</span>
                  </button>
                </ng-container>
                <ng-container>
                  <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
                    (click)="openAccountUpdateModal()" aria-label="Model Allocation / Trade">
                    <span>Model Allocation/Trade</span>
                  </button>
                </ng-container>
                <ng-container>
                  <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
                    (click)="openOrionAgentSelectModal('ap_requests')" aria-label="Orion Advisor Portal - Close Account">
                    <span>Close Account</span>
                  </button>
                </ng-container>
              </div>
            </mat-menu>
          </ng-container>
          <ng-container *appHideIfOne="perm.ViewOrionElementRequests">
            <ng-container
              *appShowIfOne="[perm.SubmitContributionRequest, perm.SubmitDistributionRequest, perm.SubmitReallocationRequest, perm.SubmitAccountCloseRequest]">
              <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
                aria-label="Open new request" (click)="openAccountUpdateModal()">
                <span>Submit New Request</span>
              </button>
            </ng-container>
          </ng-container>
          <ng-container *appShowIfOne="perm.SubmitPlanningDeskRequest">
            <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
              aria-label="Open new request" (click)="openPlanningDeskRequestModal()">
              <span>Submit Planning Desk Request</span>
            </button>
          </ng-container>
          <ng-container *appShowIfOne="perm.SubmitMessageFile">
            <button mat-menu-item class="flex items-center justify-start min-w-220 button is-lg is-inverted"
              aria-label="Open file and messages modal" (click)="openFileMessageUploadModal()">
              <span>Upload Files &amp; Messages</span>
            </button>
          </ng-container>
        </div>
      </mat-menu>
      <button class="my-10 ml-auto button is-lg is-inverted" mat-button (click)="toggleUserSidenav()">
        <span class="flex flex-row items-center justify-center">
          <span class="flex flex-col leading-tight text-right">
            <span class="text-sm font-bold">{{user?.firstName + ' ' + user?.lastName}}</span>
          </span>
          <span
            class="relative flex flex-row items-center justify-center ml-10 rounded-full bg-gray-lightest w-36 h-36">
            <span class="text-sm font-bold uppercase text-blue-dark">
              <span>
                {{ user?.firstName?.[0]}}{{ user?.lastName?.[0] }}
              </span>
            </span>
          </span>
        </span>
      </button>
    </div>
  </ng-container>
</header>
<mat-sidenav-container class="w-full mt-70 relative" autosize="true">
  @if (!loading) {
    <mat-sidenav class="w-full overflow-hidden xs:w-1/3 sm:shadow sm:w-300 dark:border-gray-dark" position="start" [disableClose]="true"
      #acmPortalSidenav [mode]="acmPortalSidenavConfig.Mode" [opened]="acmPortalSidenavConfig.IsOpened">
      <app-main-navigation class="flex flex-col h-full"></app-main-navigation>
    </mat-sidenav>
  }
  <mat-sidenav class="w-full xs:w-1/2 sm:w-300 dark:border-gray-dark" position="end" #userSidenav
    [mode]="userSidenavConfig.Mode" [opened]="userSidenavConfig.IsOpened">
    <app-user-navigation class="flex flex-col h-full"></app-user-navigation>
  </mat-sidenav>
  <mat-sidenav-content class="h-full flex flex-col overflow-hidden acm-portal-body dark:bg-black"
    #acmPortalContainerContent id="acmPortalContatainerContent" [@fadeIn]="!loading">
    <!--<app-optout-banner *ngIf="showOptOutBanner" class="block flex-none"></app-optout-banner>-->
    @if (showNotificationBanner) {
      <app-notification-banner class="block flex-none"></app-notification-banner>
    }

    <section class="flex-grow overflow-auto">
      <router-outlet></router-outlet>
    </section>
  </mat-sidenav-content>
</mat-sidenav-container>