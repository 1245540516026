<div id="investmentStrategyModal" class="flex flex-col w-full h-full">
  <header>
    <h2 class="mr-20 text-lg font-bold">Upload/Message Details</h2>
    <button mat-button class="ml-auto icon-button is-primary is-inverted" tabindex="-1" type="button"
      mat-dialog-close>
      <i class="material-icons">close</i>
    </button>
  </header>
  <section class="gap-y-20 md:gap-y-30 dialog-content">
    @if (data.createdBy) {
      <div class="flex flex-col gap-y-5">
        <h2 class="font-bold text-gray-dark">Submitted By</h2>
        <span class="text-gray">{{data.createdBy}}</span>
      </div>
    }
    @if (data.clients) {
      <div class="flex flex-col gap-y-5">
        <h2 class="font-bold text-gray-dark">Clients</h2>
        @for (client of data.clients; track client; let last = $last) {
          <span class="capitalize text-gray">
            {{ client.lastName + ', ' + client.firstName}}<br>
          </span>
        }
      </div>
    }
    <div class="flex flex-col gap-y-5">
      <h2 class="font-bold text-gray-dark" [textContent]="data.investmentAccountModel || 'No Model'"></h2>
      <span class="text-gray">{{data.custodian}}{{data.accountNumber ? ' - ' + data.accountNumber : ''}}</span>
      <span class="text-gray">Sent to {{data.recipientType}}</span>
    </div>
    <div class="flex flex-col">
      <h2 class="mb-10 font-bold text-gray-dark">Message</h2>
      <div class="flex flex-col p-10 rounded md:p-20 bg-gray-lightest">
        <span class="mb-10 text-gray-dark" [textContent]="data.taskDescription"></span>
        @if (data.createdOn) {
          <span class="text-sm text-gray">Sent {{data.createdOn | date: 'MM/dd/yyyy'}}</span>
        }
      </div>
    </div>
    @if (data.files.length) {
      <div>
        <h2 class="mb-10 font-bold text-gray-dark">File Attachments</h2>
        <div class="grid grid-cols-1 gap-10 sm:grid-cols-2">
          @for (asset of data.files; track asset) {
            <span class="flex items-center w-full transition duration-150 rounded cursor-pointer text-blue-dark hover:bg-blue-lightest">
              <a class="flex items-center w-full no-underline rounded cursor-pointer text-blue-dark hover:no-underline focus:no-underline"
                href="api/tasks/{{data.taskID}}/files/{{asset.fileID}}" target="_blank" [attr.aria-label]="'Open ' + asset.fileName" rel="noopener">
                <div class="flex items-center justify-center flex-shrink-0 w-48 h-48">
                  <mat-icon class="mi-18 text-blue-dark">description</mat-icon>
                </div>
                <span class="text-sm font-bold leading-normal">{{asset.fileName}}</span>
              </a>
            </span>
          }
        </div>
      </div>
    }
  </section>
</div>
