import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountRequestCloseModelBase, FileParameter, CloseAccountRequestsClient, AccountModel } from 'src/app/core/clients/generated/client';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { fadeIn } from 'src/app/shared/constants/animations';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalData } from 'src/app/shared/modals/confirmation-modal/modal-data.models';
import { AccountRequestModalHeaderComponent } from '../shared/components/account-request-modal-header/account-request-modal-header.component';
import { RequestExitConfirmationData } from '../shared/constants/workflow-navigation.constants';
import { DeliveryMethodEnum } from '../shared/enums/delivery-methods.enum';
import { AccountUpdateStepModel, AccountWorkflowNavModel, SolicitationOptionModel } from '../shared/models/workflow.models';
import { CloseAccountFormGroup } from './models/close-account-form.model';
import { AccountUpdateStepEnum, RequestTypeEnum } from '../shared/enums/account-update.enum';
import { TradeFrequencyEnum } from '../shared/enums/trade-frequency.enum';
import { DeliveryOptionsFormGroup } from '../shared/models/delivery-options-form.models';

@Component({
  selector: 'app-close-account-request',
  templateUrl: './close-account-request.component.html',
  animations: [fadeIn]
})
export class CloseAccountRequestComponent {
  @Output() canceled = new EventEmitter();
  @Input() account?: AccountModel;
  @Input() workflow?: AccountWorkflowNavModel;
  @Input() solicitation?: SolicitationOptionModel;
  @ViewChild('header') header?: AccountRequestModalHeaderComponent;
  requestTypeEnum = RequestTypeEnum;
  deliveryMethodEnum = DeliveryMethodEnum;
  isSubmitting = false;
  isSubmitted = false;
  isComplete = false;
  submitCount = 0;
  accountUpdateStepEnum = AccountUpdateStepEnum;
  currentStep = this.accountUpdateStepEnum.step1;

  deliveryOptionsFormGroup = this.fb.group({
    deliveryMethod: [DeliveryMethodEnum.ach, [Validators.required]],
    tradeFrequency: [TradeFrequencyEnum.oneTime, [Validators.required]],
    initiationDate: [new Date(), [Validators.required]],
    files: null,
    isOvernightCheckDelivery: false,
    journalCashRecipientAccountNumber: ['', [Validators.required]],
    wireDeliveryOptionId: ['', [Validators.required]],
  }) as DeliveryOptionsFormGroup;

  closeAccountFormGroup = this.fb.group({
    reason: [null, [Validators.required]],
    billAccountOnClosing: true,
    stateTaxAmount: [null],
    federalTaxAmount: [null],
    deliveryOptions: this.deliveryOptionsFormGroup
  }) as CloseAccountFormGroup;

  pages: AccountUpdateStepModel[] = [
    {
      formGroup: this.closeAccountFormGroup,
      value: this.accountUpdateStepEnum.step1,
    },
    {
      formGroup: this.closeAccountFormGroup,
      value: this.accountUpdateStepEnum.step2,
    },
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CloseAccountRequestComponent>,
    private snackbarService: SnackbarService,
    private closeAccountRequestsClient: CloseAccountRequestsClient,
  ) { }

  closeRequest(): void {
    if (this.isSubmitted && this.isComplete) {
      this.dialogRef.close(false);
      return;
    }

    const confirmationDialog = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
      data: RequestExitConfirmationData
    });

    confirmationDialog.afterClosed().subscribe(res => {
      if (res) this.dialogRef.close(false);
      else return;
    });
  }

  cancelRequest(): void {
    const confirmationDialog = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
      data: RequestExitConfirmationData
    });

    confirmationDialog.afterClosed().subscribe(res => {
      if (res) {
        this.canceled.emit();
      } else return;
    });
  }

  onSubmit(): void {
    if (this.account) {
      if (this.closeAccountFormGroup.invalid) {
        this.closeAccountFormGroup.markAllAsTouched();
      } else {
        this.isSubmitting = true;
        const postModel = this.constructPostModel(this.account);
        this.postRequest(postModel);
      }
    }
  }

  constructPostModel(account: AccountModel): AccountRequestCloseModelBase {
    return {
      investmentAccountID: account.investmentAccountID,
      isSolicited: this.solicitation?.isSolicited ?? true,
      requestDetail: {
        notes: this.closeAccountFormGroup.value.reason,
        isCloseDateBilled: this.closeAccountFormGroup.value.billAccountOnClosing,
        deliveryMethodID: this.closeAccountFormGroup.controls.deliveryOptions.value.deliveryMethod,
        isOvernightCheckDelivery: this.closeAccountFormGroup.controls.deliveryOptions.value.isOvernightCheckDelivery ?? undefined,
        wireDeliveryOptionID: this.closeAccountFormGroup.controls.deliveryOptions.value.wireDeliveryOptionId ?? undefined,
        fromAccountNumber: this.closeAccountFormGroup.controls.deliveryOptions.value.journalCashRecipientAccountNumber ?? undefined,
        stateTax: this.closeAccountFormGroup.value.stateTaxAmount ?? undefined,
        federalTax: this.closeAccountFormGroup.value.federalTaxAmount ?? undefined,
      }
    };
  }

  postRequest(closeRequest: AccountRequestCloseModelBase): void {
    const files: FileParameter[] = this.closeAccountFormGroup.controls.deliveryOptions.value.files?.map(file => {
      return { data: file, fileName: file.name };
    }) || [];

    this.closeAccountRequestsClient.createCloseAccountRequest(files, closeRequest).subscribe({
      error: () => {
        this.submitCount++;
        // for work around on sometimes the first request failing submitting to the crm.
        if (this.submitCount < 2){
          console.log('received error, retrying in 2 seconds');
          setTimeout(() =>{
            this.onSubmit();
          }, 2000);
        } else {
          this.isSubmitting = false;
          this.isSubmitted = true;
          this.isComplete = false;
          this.submitCount = 0;
        }
      },
      complete: () => {
        this.isSubmitting = false;
        this.isSubmitted = true;
        this.isComplete = true;
      }
    });
  }

  previousStep(): void {
    if (this.currentStep > this.accountUpdateStepEnum.step1) {
      this.currentStep--;
    }
  }

  nextStep(formGroup: UntypedFormGroup): void {
    if (formGroup.invalid) {
      formGroup.markAllAsTouched();
    } else {
      if ((this.currentStep < this.accountUpdateStepEnum.step2)) {
        this.currentStep++;
      }
    }
  }
}