@if (!isLoading) {
  <form @fadeIn [formGroup]="paperApplicationForm" class="flex flex-col w-full h-full" (ngSubmit)="onSubmit()">
    <header>
      <h2 class="m-10 mr-20 text-lg font-bold">Submit Paperwork for Account Opening</h2>
      <button mat-button class="m-10 ml-auto icon-button is-primary is-inverted" [disabled]="isSubmitting" type="button" mat-dialog-close>
        <i class="material-icons">close</i>
      </button>
    </header>
    @if (isSubmitting && !isSubmitted) {
      <div class="flex-grow w-full loader-wrap" @fadeIn>
        <div class="loader-sm is-blue"></div>
      </div>
    }
    @if (!isSubmitting && isSubmitted) {
      <section class="">
        @if (isComplete === true) {
          <div class="flex flex-col items-center justify-center self-center w-full gap-y-30 min-h-500">
            <span class="material-icons mi-96 text-green-dark">task_alt</span>
            <label class="font-bold">Thank You!</label>
            <label class="font-semibold">Your new paper application request has been submitted successfully.</label>
            <button type="button" class="m-10 button is-primary is-inverted is-lg is-outlined flex justify-center"
            (click)="closeRequest()">Close</button>
          </div>
        }
        @if (isComplete === false) {
          <div class="flex flex-col items-center justify-center self-center w-full gap-y-30 min-h-300">
            <span class="material-icons mi-96 text-red-dark">error</span>
            <label class="font-bold">Uh Oh!</label>
            <label class="font-semibold">Something went wrong with submitting your paper application request.</label>
            <label class="font-semibold">Please Try submitting again.</label>
          </div>
        }
      </section>
    }
    @if (!isSubmitting && !isSubmitted) {
      <section class="flex flex-col flex-grow p-30 overflow-auto gap-y-20">
        <div class="flex flex-col flex-none">
          <span class="text-lg text-gray-dark">{{instruction}}</span>
        </div>
        <!-- IAR and Client -->
        <div class="flex flex-col flex-none gap-y-10">
          <div class="flex flex-row justify-between" [ngClass]="{'gap-x-10': paperApplicationForm.get('Iar')?.valid, 'pr-10': !paperApplicationForm.get('Iar')?.valid}">
            <div class="flex flex-col w-1/2">
              <label class="mb-5 text-sm text-gray required-label">IAR</label>
              <mat-select class="select" (selectionChange)="onChangeIars()" formControlName="Iar" name="iar" placeholder="Select IAR" aria-label="Select IAR">
                @for (iar of iars; track iar) {
                  <mat-option [value]="iar.agentID">{{iar.firstName + ' ' + iar.lastName}}</mat-option>
                }
              </mat-select>
              @if (paperApplicationForm.controls['Iar'].touched && paperApplicationForm.controls['Iar'].errors) {
                <span class="w-full mt-5 text-xs text-red">
                  @if (paperApplicationForm.controls['Iar'].hasError('required')) {
                    <span>This field is required.</span>
                  }
                </span>
              }
            </div>
            @if (paperApplicationForm.get('Iar')?.valid) {
              <div class="flex flex-col w-1/2">
                <label class="mb-5 text-sm text-gray required-label" for="Client">Client</label>
                <input class="w-full input" type="text" matInput placeholder="Select a Client" formControlName="Client" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayClient" (optionSelected)="onClientChange($event)">
                  <mat-option [value]="newClient">
                    <span class="flex items-center gap-x-10 text-blue">
                      <span>{{newClient.firstName}}</span>
                      <mat-icon svgIcon="plus"></mat-icon>
                    </span>
                  </mat-option>
                  @for (client of filteredClients | async ; track client) {
                    <mat-option class="capitalize" [value]="client">
                      {{client.lastName + ', ' + client.firstName}}
                    </mat-option>
                  }
                </mat-autocomplete>
                @if (paperApplicationForm.controls['Client'].touched && paperApplicationForm.controls['Client'].errors) {
                  <span class="w-full mt-5 text-xs text-red">
                    @if (paperApplicationForm.controls['Client'].hasError('required')) {
                      <span>This field is required.</span>
                    }
                  </span>
                }
              </div>
            }
          </div>
          <!-- New Client Information -->
          @if (paperApplicationForm.get('NewClient')) {
            <div class="flex flex-col flex-none gap-y-10" formGroupName='NewClient'>
              <div class="flex flex-row gap-x-10 justify-between">
                <div class="flex flex-col w-1/2">
                  <label class="mb-5 text-sm text-gray required-label" for="FirstName">New Client's First Name</label>
                  <input type="text"
                    matInput
                    class="w-full input"
                    placeholder="First Name"
                    formControlName="FirstName">
                  @if (paperApplicationForm.controls['NewClient'].get('FirstName')?.touched && paperApplicationForm.controls['NewClient'].get('FirstName')?.errors) {
                    <span class="w-full mt-5 text-xs text-red">
                      @if (paperApplicationForm.controls['NewClient'].get('FirstName')?.hasError('required')) {
                        <span>This field is required.</span>
                      }
                    </span>
                  }
                </div>
                <div class="flex flex-col w-1/2">
                  <label class="mb-5 text-sm text-gray required-label" for="LastName">New Client's Last Name</label>
                  <input type="text"
                    matInput
                    class="w-full input"
                    placeholder="Last Name"
                    formControlName="LastName">
                  @if (paperApplicationForm.controls['NewClient'].get('LastName')?.touched && paperApplicationForm.controls['NewClient'].get('LastName')?.errors) {
                    <span class="w-full mt-5 text-xs text-red">
                      @if (paperApplicationForm.controls['NewClient'].get('LastName')?.hasError('required')) {
                        <span>This field is required.</span>
                      }
                    </span>
                  }
                </div>
              </div>
              <div class="flex flex-col">
                <label class="mb-5 text-sm text-gray required-label" for="Email">New Client's Email</label>
                <input type="text"
                  matInput
                  class="w-full input"
                  placeholder="Email"
                  formControlName="Email">
                @if (paperApplicationForm.controls['NewClient'].get('Email')?.touched && paperApplicationForm.controls['NewClient'].get('Email')?.errors) {
                  <span class="w-full mt-5 text-xs text-red">
                    @if (paperApplicationForm.controls['NewClient'].get('Email')?.hasError('required')) {
                      <span>This field is required.</span>
                    }
                  </span>
                }
              </div>
            </div>
          }
        </div>
        <!-- Custodian and Account Type -->
        <div class="flex flex-col flex-none">
          <div class="flex flex-row gap-x-10 items-stretch">
            <div class="flex flex-col w-full">
              <label class="mb-5 text-sm text-gray required-label" for="CustodianID">Custodian</label>
              <mat-select class="select"
                formControlName="CustodianID" name="custodianID"
                placeholder="Select a Custodian" aria-label="Select Custodian">
                @for (custodian of custodians; track custodian) {
                  <mat-option [value]="custodian.custodianID">{{custodian.name}}</mat-option>
                }
              </mat-select>
              @if (paperApplicationForm.controls['CustodianID'].touched && paperApplicationForm.controls['CustodianID'].errors) {
                <span class="w-full mt-5 text-xs text-red">
                  @if (paperApplicationForm.controls['CustodianID'].hasError('required')) {
                    <span>This field is required.</span>
                  }
                </span>
              }
            </div>
            <div class="flex flex-col w-full">
              <label class="mb-5 text-sm text-gray required-label" for="InvestmentAccountTypeID">Account Type</label>
              <mat-select class="select"
                formControlName="InvestmentAccountTypeID" name="investmentAccountTypeID"
                placeholder="Select an Account Type" aria-label="Select an Account Type">
                @for (investmentAccountType of investmentAccountTypes; track investmentAccountType) {
                  <mat-option [value]="investmentAccountType.id">{{investmentAccountType.name}}</mat-option>
                }
              </mat-select>
              @if (paperApplicationForm.controls['InvestmentAccountTypeID'].touched && paperApplicationForm.controls['InvestmentAccountTypeID'].errors) {
                <span class="w-full mt-5 text-xs text-red">
                  @if (paperApplicationForm.controls['InvestmentAccountTypeID'].hasError('required')) {
                    <span>This field is required.</span>
                  }
                </span>
              }
            </div>
          </div>
        </div>
        <!-- Model -->
        @if (paperApplicationForm.get('Iar')?.valid) {
          <div class="flex flex-col flex-none">
            <div class="flex flex-col w-full">
              <label class="mb-5 text-sm text-gray required-label" for="InvestmentAccountModelID">Model</label>
              <mat-select class="select"
                formControlName="InvestmentAccountModelID" name="investmentAccountModelID"
                placeholder="Select a Model" aria-label="Select a Model">
                @for (model of investmentModels; track model) {
                  <mat-option [value]="model.id">{{model.name}}</mat-option>
                }
                @if (!investmentModels.length) {
                  <mat-option>No investment models available for selected IAR</mat-option>
                }
              </mat-select>
              @if (paperApplicationForm.controls['InvestmentAccountModelID'].touched && paperApplicationForm.controls['InvestmentAccountModelID'].errors) {
                <span class="w-full mt-5 text-xs text-red">
                  @if (paperApplicationForm.controls['InvestmentAccountModelID'].hasError('required')) {
                    <span>This field is required.</span>
                  }
                </span>
              }
            </div>
          </div>
        }
        <!-- Notes -->
        <div class="flex flex-col flex-none">
          <label class="mb-5 text-sm text-gray required-label" for="notes">Notes</label>
          <textarea class="textarea" rows="4"
            placeholder="Enter Notes Here"
          aria-label="Notes input" formControlName="Notes" name="notes"></textarea>
        </div>
        <!-- Documents -->
        <div class="flex flex-col flex-none">
          <label class="mb-5 text-sm text-gray required-label">Uploads</label>
          <div class="flex flex-col flex-none pb-10 relative">
            <app-file-dropper (fileChangeEvent)="uploadFiles($event)"></app-file-dropper>
            @if (paperApplicationForm.controls['Files'].touched && paperApplicationForm.controls['Files'].errors) {
              <span class="w-full mt-5 text-xs text-red absolute top-100 left-0">
                @if (paperApplicationForm.controls['Files'].hasError('required')) {
                  <span>This field is required.</span>
                }
              </span>
            }
          </div>
        </div>
      </section>
    }
    @if (!isSubmitting && !isComplete) {
      <footer>
        <button mat-button type="button" class="m-10 button is-primary is-inverted is-lg is-outlined" mat-dialog-close [disabled]="isSubmitting">Cancel</button>
        <button mat-button type="submit" class="m-10 button is-primary is-lg ml-auto" [disabled]="isSubmitting">{{isSubmitting ? "Submitting" : "Submit"}}</button>
      </footer>
    }
  </form>
} @else {
  <div @fadeIn class="flex-grow w-full loader-wrap">
    <div class="loader-sm is-blue"></div>
  </div>
}