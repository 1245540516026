<div class="flex flex-col items-center flex-grow p-20 text-center bg-no-repeat bg-cover card bg-wave-sm gap-y-20 cursor-pointer hover:shadow-lg transition duration-150" (click)="openInvestmentDetailsModal()">
  <span class="flex items-center justify-center w-48 h-48 mt-10 rounded-full bg-gray-lighter">
    <img [src]="model.LogoUrl" [alt]="model.Name" class="w-24 h-24">
  </span>
  <div class="flex flex-col gap-y-5">
    <h3 class="text-sm font-bold">{{model.Name}}</h3>
    <span class="text-xs font-semibold text-gray" [innerText]="model.RiskTolerance"></span>
  </div>
  <div class="flex flex-wrap justify-center mt-auto gap-x-10 gap-y-10">
    @for (tag of model.Tags; track tag) {
      <span class="px-8 py-5 font-semibold uppercase rounded-full text-2xs bg-gray-lighter">{{tag}}</span>
    }
  </div>
</div>