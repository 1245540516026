@if (parentForm && parentForm.valid) {
  <section class="flex flex-col gap-y-30">
    <p class="text-lg">Please review your request below and make sure all the information is accurate.</p>
    <div class="flex flex-col gap-y-10">
      <app-review-row-entry [dataName]="'Client Name'" [dataValue]="parentForm.value.generalInfo.client"></app-review-row-entry>
      <app-review-row-entry [dataName]="'Next Appointment Date'" [dataValue]="parentForm.value.generalInfo.nextAppointmentDate | date: 'MM/dd/yyyy'"></app-review-row-entry>
      <app-review-row-entry [dataName]="'Request Type'" [dataValue]="parentForm.value.generalInfo.requestType.name"></app-review-row-entry>
      @if (parentForm.value.generalInfo.requestType.value === portfolioAnalysisCategoryEnum.analysisAndProposal) {
        <app-review-row-entry [dataName]="'Report Analysis'" [dataValue]="recommendationType"></app-review-row-entry>
      }
      <app-review-row-entry [dataName]="'Requested By'" [dataValue]="parentForm.value.generalInfo.agent.firstName + ' ' + parentForm.value.generalInfo.agent.lastName"></app-review-row-entry>
      <span class="divider-horizontal my-20"></span>
      @for (item of parentForm.value.assetDetails.assetBreakdown; track item) {
        <div class="flex flex-col gap-y-10">
          <app-review-row-entry [dataName]="item.accountType.name" [dataValue]="item.currentValue | currency: '$'"></app-review-row-entry>
          <app-review-row-entry [dataName]="'Client Fee'" [dataValue]="item.clientFee" class="text-sm pl-20"></app-review-row-entry>
        </div>
      }
      <div class="py-30 mt-20 mb-15 border-t border-b border-gray-lighter">Report Type(s): <span class="font-bold">{{requestReports}}</span></div>
      <div class="flex flex-col gap-y-10">
        <span>Notes:</span>
        @if (parentForm.value.generalInfo.notes) {
          <div class="flex flex-col p-10 rounded md:p-20 bg-gray-lightest">
            <span class="mb-10 whitespace-pre-wrap overflow-wrap-text text-gray-dark">{{parentForm.value.generalInfo.notes}}</span>
          </div>
        }
        @if (!parentForm.value.generalInfo.notes) {
          <span class="text-gray text-sm italic">No notes.</span>
        }
      </div>
    </div>
    <div class="flex flex-col">
      <span>Files:</span>
      @for (item of parentForm.value.assetDetails.files; track item) {
        <div class="flex flex-col gap-y-5" class="font-bold">
          {{item.name}}
        </div>
      }
    </div>
  </section>
}