@if (form) {
  <form class="flex flex-col gap-y-30" [formGroup]="form">
    @if (formHasError && formTouched && investmentRequestFormService?.showValidationErrorPanel) {
      <app-message-notification
        panelType="error">
        <div class="flex flex-col gap-y-4">
          @if (allocationAmountNotEqual) {
            <p>{{investmentRequestFormService?.allocationAmountNotEqualError}}</p>
          }
          @if (noAllocationSet) {
            <p>At least one model needs a specified amount.</p>
          }
          @if (newSleeveInvalid) {
            <p>All new model field are required.</p>
          }
          @if (protectedCashValueRequired) {
            <p>A cash amount & fee percentage are required.</p>
          }
          @if (needsCustomHoldingAllocation) {
            <p>You must define at least one trade.</p>
          }
        </div>
      </app-message-notification>
    }
    @if (hasCustomHoldings && requestType === requestTypeEnum.reallocation) {
      <div class="flex flex-col">
        <label class="text-base font-bold text-gray-dark required-label mb-15" for="isTradesOnly">What would you like to do?</label>
        <mat-radio-group formControlName="isTradesOnly" class="flex flex-col" (click)="ResetForm();">
          @for (item of allocationInputOptions; track item) {
            <mat-radio-button [value]="item.value"><span
            class="pl-5 small-radio-button">{{item.name}}</span></mat-radio-button>
          }
        </mat-radio-group>
      </div>
    }
    <!--<div *ngIf="form?.value?.isTradesOnly">
    TRADES ONLY!!!!
    </div>
    <div *ngIf="!form?.value?.isTradesOnly">
      NOT TRADES ONLY!!!!
    </div>-->
    @if (!form.value.isTradesOnly) {
      <div class="flex flex-col gap-y-10">
        @if (investmentRequestFormService?.allocationHeader) {
          <h1 class="font-bold">
          {{investmentRequestFormService?.allocationHeader}}</h1>
        }
        <div class="flex flex-col">
          <div class="flex flex-row w-full items-center">
            <span class="uppercase font-bold text-sm pr-5">Amount to {{requestType ===
              requestTypeEnum.contribution ? 'Contribute': requestType === requestTypeEnum.distribution ?
            'Distribute' : 'Allocate'}}</span>
            <span class="ml-5 font-bold text-base"
              [ngClass]="remainingBalance && remainingBalance < 0 ? 'text-red' : 'text-blue-dark'">{{remainingBalance
            | currency}}</span>
          </div>
        </div>
        @if (requestType === requestTypeEnum.reallocation) {
          <div class="flex flex-col gap-y-10 w-full mt-10">
            <label class="text-base font-bold text-gray-dark required-label" for="net-amount">Would you like to allocate
            using dollars or percentages?</label>
            <mat-button-toggle-group id="percent-dollar-toggle" formControlName="type" class="w-150 button-toggle-group"
              autofocus>
              <mat-button-toggle class="w-full" [value]="Dollar">$
              </mat-button-toggle>
              <mat-button-toggle class="w-full" [value]="Percent">%
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        }
        @if (requestType === requestTypeEnum.contribution && !hasOnlyCustomHoldings) {
          <mat-checkbox
            class="flex-none text-sm mt-15" formControlName="useCurrentSetup">
            <span class="ml-2 mr-2 ">Contribute funds across all models using current allocations</span>
            <mat-icon class="mi-18 text-blue-light" svgIcon="information"
                  matTooltip="
                              Current Allocations = the % distribution of funds across all models.&NewLine;&NewLine;Example: Two funds (A & B) might have a 60/40 % split where 60% of the allocations would go to model A and 40% would go to model B."
              matTooltipPosition="after"
              matTooltipClass="text-sm border rounded-t rounded-b border-blue-lighter bg-blue-lightest text-gray-dark">
            </mat-icon>
          </mat-checkbox>
        }
        <!--<mat-checkbox *ngIf="requestType === requestTypeEnum.distribution && !hasOnlyCustomHoldings" class="flex-none text-sm mt-15"
        formControlName="sellAllEvenly">
        <span class="ml-3">Distribute funds evenly from each model</span>
        </mat-checkbox>-->
      </div>
    }
    <div class="flex flex-col gap-y-10">
      <div class="grid text-xs font-semibold uppercase text-gray"
        [class]="requestType !== requestTypeEnum.reallocation ? 'grid-cols-allocation gap-x-10' : 'grid-cols-reallocation gap-x-10'">
        <span>Model</span>
        @if (requestType === requestTypeEnum.reallocation && !form.value.isTradesOnly) {
          <span>Account Fee</span>
        }
        @if (!form.value.isTradesOnly) {
          <span>{{requestType ===
            requestTypeEnum.contribution ? 'Contribution': requestType === requestTypeEnum.distribution ?
          'Distribution' : 'Allocation'}} Amount</span>
        }
      </div>
      @for (sleeve of sleevesFormArray; track sleeve; let i = $index) {
        <ng-container formArrayName="sleeves">
          <div class="grid items-center rounded-sm" [formGroupName]="i"
            [class]="(requestType !== requestTypeEnum.reallocation ? 'grid-cols-allocation gap-x-10' : 'grid-cols-reallocation gap-x-10') + (sleeve.value.isNewSleeve ? ' bg-gray-lightest' : '')">
            <div class="flex flex-col rounded-lg">
              <label class="text-sm font-bold uppercase">{{sleeve.value.accountNumber}}</label>
              <label class="text-sm font-bold uppercase">
                {{sleeve.value.model || 'No Current Model'}}
                @if (sleeve.value.isCustomSleeve) {
                  <mat-icon class="mi-18 text-blue-light"
                    svgIcon="information" matTooltip="Click 'Enter Trades' to buy/sell custom holdings"
                    matTooltipPosition="after"
                    matTooltipClass="text-sm border rounded-t rounded-b border-blue-lighter bg-blue-lightest text-gray-dark">
                  </mat-icon>
                }
              </label>
              <label class="text-sm text-gray">
                Current Value: {{sleeve.value.currentValue | currency}}
              </label>
              @if (requestType !== requestTypeEnum.contribution) {
                <label class="text-sm text-gray">
                  Cash Value: {{sleeve.value.cashValue || 0 | currency}}
                </label>
              }
            </div>
            <!--account fee input -->
            @if (requestType === requestTypeEnum.reallocation && !form.value.isTradesOnly) {
              <input currencyMask onfocus="this.select()"
                [options]="accountTotalFeeMaskOptions" formControlName="accountTotalFee" type="text"
                placeholder="% Fee" class="input text-sm min-w-64" />
            }
            <!--amount input - contribution/distribution -->
            @if (requestType !== requestTypeEnum.reallocation) {
              <div class="flex flex-none h-34">
                <input currencyMask [options]="sleeveAmountMaskOptions" placeholder="$ Amount" type="text" min="1" step="any" onfocus="this.select()"
                  class="rounded-r-none input flex-grow text-sm min-w-120" formControlName="amount">
                <div
                  class="flex items-center justify-center flex-none w-58 text-white border-solid rounded-r bg-gray border-gray-lighter">
                  @if (initialAmount) {
                    <label class="font-bold">{{(sleeve.value.amount / initialAmount) |
                    percent:'1.0-1'}}</label>
                  }
                </div>
              </div>
            }
            <!--amount input - reallocation -->
            @if (requestType === requestTypeEnum.reallocation && !form.value.isTradesOnly) {
              <div>
                @if (form.value.type === Dollar) {
                  <div class="flex flex-none h-34">
                    <input currencyMask [options]="sleeveAmountMaskOptions" placeholder="$ Amount" type="text" min="1" step="any"
                      onfocus="this.select()" class="rounded-r-none input flex-grow text-sm"
                      formControlName="amount">
                    <div
                      class="flex items-center justify-center flex-none w-58 text-white border-solid rounded-r bg-gray border-gray-lighter">
                      @if (initialAmount) {
                        <label currencyMask for="percentage" class="font-bold">
                        {{(sleeve.value.percentage ? sleeve.value.percentage : 0) | number:'1.0-2'}}%</label>
                      }
                    </div>
                  </div>
                }
                @if (form.value.type === Percent) {
                  <div class="flex flex-none h-34">
                    <input currencyMask [options]="sleevePercentageMaskOptions" placeholder="% Amount" type="text"
                      onfocus="this.select()" min="1" step="any"
                      class="rounded-r-none input flex-grow text-sm min-w-72" formControlName="percentage">
                    <div
                      class="flex items-center justify-center flex-none w-120 text-white border-solid rounded-r bg-gray border-gray-lighter">
                      @if (initialAmount) {
                        <label currencyMask for="amount" class="font-bold">
                        {{(sleeve.value.amount ? sleeve.value.amount : 0) | currency}}</label>
                      }
                    </div>
                  </div>
                }
              </div>
            }
            <div class="flex flex-none">
              @if (sleeve.value.isCustomSleeve) {
                <button matTooltip="Set Up Custom Holdings" type="button"
                  class="is-outlined bg-blue-lightest is-wide is-inverted relative mr-5"
                  [class]="needsCustomHoldingAllocation ? 'is-warning' : 'is-primary'"
                  [disabled]="!sleeve.value.amount && !form.value.isTradesOnly" (click)="showCustomHoldings()">
                  <span class=text-xs>Enter Trades</span>
                  <!-- <span
                  class="absolute top-0 left-0 w-full h-full bg-red-lighter opacity-50 rounded-full animate-ping"
                *ngIf="needsCustomHoldingAllocation"></span> -->
              </button>
            }
            @if (sleeve.value.isNewSleeve) {
              <button
                [ngClass]="{'-ml-10' : requestType !== requestTypeEnum.reallocation}" type="button"
                class="icon-button is-warning is-inverted" (click)="removeSleeve(i)">
                <i class="material-icons">close</i>
              </button>
            }
          </div>
        </div>
        @if (sleeve.value.isCustomSleeve && customHoldingAllocations?.controls?.length) {
          <section>
            <app-allocation-summary [customHoldingAllocations]="customHoldingAllocations"></app-allocation-summary>
          </section>
        }
      </ng-container>
    }
    @if (requestType === requestTypeEnum.reallocation && !form.value.isTradesOnly) {
      <app-allocation-add-sleeve (newSleeveAdded)="addNewSleeve($event.sleeve, $event.showCustomHoldings);"
        [form]="form"
        [investmentRequestFormService]="investmentRequestFormService"
      [initialAmount]="initialAmount"></app-allocation-add-sleeve>
    }
    @if (requestType === requestTypeEnum.contribution && !hasCashSleeve && !useCurrentSetup.value) {
      <div class="flex flex-col gap-y-10 mt-10">
        <div class="flex flex-col">
          <mat-checkbox formControlName="useProtectedCash" class="flex-none text-sm">
            <span class="ml-3">Contribute to cash</span>
          </mat-checkbox>
        </div>
      </div>
    }
    @if (useProtectedCash) {
      <div class="grid items-center rounded-sm grid-cols-protectedcash gap-x-10" formGroupName="protectedCashAmount">
        <div><!-- needed for grid class --></div>
        <label class="text-xs font-semibold uppercase text-gray">
          Account Fee
        </label>
        <label class="text-xs font-semibold uppercase text-gray">
          Contribution Amount
        </label>
        <div><!-- needed for grid class --></div>
      </div>
    }
    @if (useProtectedCash) {
      <div class="grid items-centerrounded-sm grid-cols-protectedcash gap-x-10" formGroupName="protectedCashAmount">
        <div class="flex flex-col pl-4 rounded-lg">
          <label class="text-sm font-bold uppercase">
            Cash
          </label>
        </div>
        <input currencyMask [options]="accountTotalFeeMaskOptions" placeholder="Account Fee" type="text" min="1"
          onfocus="this.select()" step="any" class="input text-sm min-w-64" formControlName="fee">
        <div class="flex flex-none h-34">
          <input currencyMask placeholder="$ Amount" type="text" min="1" step="any" onfocus="this.select()"
            class="input rounded-r-none flex-grow text-sm" formControlName="amount">
          <div
            class="flex items-center justify-center bg-gray text-white border-solid border-gray-lighter rounded-r flex-none w-58">
            @if (initialAmount) {
              <label class="font-bold">{{(protectedCashAmount/
              initialAmount) | percent:'1.0-1'}}</label>
            }
          </div>
        </div>
        <div><!-- needed for grid class --></div>
      </div>
    }
    </div>
    <app-allocation-notes [form]="form"></app-allocation-notes>
  </form>
}