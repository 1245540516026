import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccountModel } from 'src/app/core/clients/generated/client';
import { ContributionFormService } from 'src/app/portal/features/account-request-workflows/contribution-request/services/contribution-form.service';
import { SnackbarService } from 'src/app/core/services/snackbar/snackbar.service';
import { fadeIn } from 'src/app/shared/constants/animations';
import { ConfirmationModalComponent } from 'src/app/shared/modals/confirmation-modal/confirmation-modal.component';
import { ConfirmationModalData } from 'src/app/shared/modals/confirmation-modal/modal-data.models';
import { RequestExitConfirmationData } from '../shared/constants/workflow-navigation.constants';
import { AccountUpdateStepEnum, RequestTypeEnum } from '../shared/enums/account-update.enum';
import { AccountWorkflowNavModel, SolicitationOptionModel } from '../shared/models/workflow.models';
import { AllocationSleeveFormArray, AllocationFormGroup } from '../shared/models/allocation-form.models';
import { DeliveryOptionsFormGroup } from '../shared/models/delivery-options-form.models';
import { ContributionFundsFormGroup } from './models/contribute-funds-form-group';
import { AccountRequestModalHeaderComponent } from '../shared/components/account-request-modal-header/account-request-modal-header.component';

@Component({
  selector: 'app-contribution-request',
  templateUrl: './contribution-request.component.html',
  providers: [ ContributionFormService ],
  animations: [ fadeIn ]
})
export class ContributionRequestComponent implements OnInit {
  @Output() canceled = new EventEmitter();
  @Input() account?: AccountModel;
  @Input() workflow?: AccountWorkflowNavModel;
  @Input() solicitation?: SolicitationOptionModel;
  @ViewChild('header') header?:AccountRequestModalHeaderComponent;
  accountUpdateStepEnum = AccountUpdateStepEnum;
  requestTypeEnum = RequestTypeEnum;
  pages = this.contributionFormService.pages;
  form = this.contributionFormService.form;
  isSubmitting = false;
  isSubmitted = false;
  isComplete = false;
  submitCount = 0;
  constructor (
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ContributionRequestComponent>,
    private snackbarService: SnackbarService,
    public contributionFormService: ContributionFormService
  ) {}

  ngOnInit(): void {
    if (this.account) {
      this.contributionFormService.account = this.account;
      this.contributionFormService.addSleeves(this.account);
      this.form.controls.allocationConfiguration.controls.useCurrentSetup.setValue(false);
    }

    if (this.solicitation) {
      this.contributionFormService.isSolicited = this.solicitation?.isSolicited;
    }
  }

  get showCustomHoldings(): boolean {
    return this.contributionFormService.allocationFormGroup.value.showCustomHoldings;
  }

  get contributeFundsFormGroup(): ContributionFundsFormGroup {
    return this.form.controls.contributionFunds as ContributionFundsFormGroup;
  }

  get initialAmount(): number {
    return this.contributionFormService.contributeFundsFormGroup?.value.contributionAmount as number;
  }

  get sleevesFormArray(): AllocationSleeveFormArray {
    return this.contributionFormService.allocationFormGroup.controls.sleeves as AllocationSleeveFormArray;
  }

  /** return FormGroup for step 2 */
  get allocationFormGroup(): AllocationFormGroup {
    return this.form.controls.allocationConfiguration as AllocationFormGroup;
  }

  /** return FormGroup for step 3 */
  get deliveryOptionsFormGroup(): DeliveryOptionsFormGroup {
    return this.form.controls.deliveryOptions as DeliveryOptionsFormGroup;
  }

  closeRequest(): void {
    if (this.isSubmitted && this.isComplete) {
      this.dialogRef.close(false);
      return;
    }

    const confirmationDialog = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
      data: RequestExitConfirmationData
    });

    confirmationDialog.afterClosed().subscribe(res => {
      if (res) this.dialogRef.close(false);
      else return;
    });
  }

  cancelRequest(): void {
    const confirmationDialog = this.dialog.open<ConfirmationModalComponent, ConfirmationModalData>(ConfirmationModalComponent, {
      data: RequestExitConfirmationData
    });

    confirmationDialog.afterClosed().subscribe(res => {
      if (res) {
        this.canceled.emit();
      } else return;
    });
  }

  onSubmit(): void {
    if (this.account) {
      if (this.form.invalid) {
        this.form.markAllAsTouched();
      } else {
        this.isSubmitting = true;
        this.contributionFormService.postRequest(this.account.investmentAccountID)
          .subscribe({
            error: () => {
              this.submitCount++;
              // for work around on sometimes the first request failing submitting to the crm.
              if (this.submitCount < 2){
                console.log('received error, retrying in 2 seconds');
                setTimeout(() =>{
                  this.onSubmit();
                }, 2000);
              } else {
                this.isSubmitting = false;
                this.isSubmitted = true;
                this.isComplete = false;
                this.submitCount = 0;
              }
            },
            complete: () => {
              this.isSubmitting = false;
              this.isSubmitted = true;
              this.isComplete = true;
            }
          });
      }
    }
  }
}