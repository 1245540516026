@if (parentForm && requestType) {
  <form [formGroup]="parentForm" class="flex flex-col w-full">
    <section class="flex flex-col overflow-auto p-30 gap-y-30">
      <!-- Method -->
      <!-- Required field -->
      <div class="flex flex-col gap-y-10">
        <div class="flex flex-row items-center gap-x-5">
          <label class="text-base font-bold text-gray-dark required-label">Please choose a
          {{requestTypeLabel}} method</label>
          @if (requestType === requestTypeEnum.contribution) {
            <mat-icon class="mi-18 text-blue-light" svgIcon="information" matTooltip="Only ACH is supported at this time"
              matTooltipPosition="after"
              matTooltipClass="text-sm border rounded-t rounded-b border-blue-lighter bg-blue-lightest text-gray-dark">
            </mat-icon>
          }
        </div>
        <div class="flex flex-col gap-y-5">
          <mat-select formControlName="deliveryMethod"
            (selectionChange)="updateDeliveryMethod(parentForm.value.deliveryMethod)" class="select" required>
            @for (option of deliveryMethodList; track option) {
              <mat-option [value]="option.value">
                {{option.name}}
              </mat-option>
            }
          </mat-select>
          @if (parentForm.controls.deliveryMethod.touched && parentForm.controls.deliveryMethod.errors) {
            <span
              class="w-full text-xs -mt-15 text-red">
              @if (parentForm.controls.deliveryMethod.hasError('required')) {
                <span>This field is required.</span>
              }
            </span>
          }
        </div>
        @if (parentForm.controls.isOvernightCheckDelivery.enabled) {
          <mat-checkbox formControlName="isOvernightCheckDelivery">Overnight Delivery?</mat-checkbox>
        }
        @if (parentForm.controls.wireDeliveryOptionId.enabled) {
          <div class="w-1/2 flex flex-col gap-y-5">
            <mat-select class="select" placeholder="Choose whom to transfer to" formControlName="wireDeliveryOptionId">
              @for (option of transferRecipientList; track option) {
                <mat-option [value]="option.value">{{option.name}}</mat-option>
              }
            </mat-select>
            @if (parentForm.controls.wireDeliveryOptionId.touched && parentForm.controls.wireDeliveryOptionId.errors) {
              <span
                class="w-full text-xs text-red">
                @if (parentForm.controls.wireDeliveryOptionId.hasError('required')) {
                  <span>This field is required.</span>
                }
              </span>
            }
          </div>
        }
        @if (parentForm.value.wireDeliveryOptionId === transferRecipientEnum.thirdParty) {
          <div class="flex flex-row gap-x-10">
            <mat-icon class="mi-18 text-blue-light flex-shrink-0" svgIcon="information"></mat-icon>
            <span class="text-xs">Third party wire transfers require an applicable <a target="_blank" href="/api/wistia/download?form=tdwire">TD Ameritrade</a> or <a target="_blank" href="/api/wistia/download?form=schwabwire">Schwab</a> wire transfer instructions form that is signed by the client. Please download and attach the applicable form with client signature.</span>
          </div>
        }
        @if (parentForm.controls.journalCashRecipientAccountNumber.enabled) {
          <div class="w-1/2 flex flex-col gap-y-5">
            <input class="input" formControlName="journalCashRecipientAccountNumber" placeholder="Enter account number" />
            @if (parentForm.controls.journalCashRecipientAccountNumber.touched && parentForm.controls.journalCashRecipientAccountNumber.errors) {
              <span class="w-full text-xs text-red">
                @if (parentForm.controls.journalCashRecipientAccountNumber.hasError('required')) {
                  <span>This field is required.</span>
                }
              </span>
            }
          </div>
        }
      </div>
      <!-- Frequency -->
      <!-- Required field -->
      @if (requestType !== requestTypeEnum.closeAccount) {
        <div class="flex flex-col gap-y-10" >
          <label class="text-base font-bold text-gray-dark required-label" for="tradeFrequency">How often is the {{requestTypeLabel}} made?</label>
          <div class="flex flex-col gap-y-10">
            <mat-select formControlName="tradeFrequency" (selectionChange)="updateDeliveryFrequency(parentForm.value.tradeFrequency)" class="select" required>
              @for (option of tradeFrequencyList; track option) {
                <mat-option [value]="option.value">
                  {{option.name}}
                </mat-option>
              }
            </mat-select>
            @if (parentForm.controls.tradeFrequency.touched && parentForm.controls.tradeFrequency.errors) {
              <span
                class="w-full text-xs text-red">
                @if (parentForm.controls.tradeFrequency.hasError('required')) {
                  <span>This field is
                  required.</span>
                }
              </span>
            }
          </div>
        </div>
      }
      <!-- Date to initiate -->
      @if (parentForm.controls.initiationDate.enabled) {
        <div class="flex flex-col gap-y-10">
          <label class="text-base font-bold text-gray-dark required-label" for="initiationDate">When should
          the {{requestTypeLabel}} initiate?</label>
          <div class="datepicker-input-container">
            <input [matDatepicker]="picker" class="datepicker-input" [min]="minDate" formControlName="initiationDate" (click)="picker.open()" readonly
              placeholder="mm/dd/yyyy">
              <mat-icon class="datepicker-icon" svgIcon="calendar" matDatepickerToggleIcon></mat-icon>
              <mat-datepicker #picker color="accent"></mat-datepicker>
            </div>
            @if (parentForm.controls.initiationDate.touched) {
              <span
                class="w-full text-xs -mt-15 text-red">
              @if (parentForm.controls.initiationDate.hasError('required')) {
                <span>This field is required.</span>
              }
            </span>
          }
        </div>
      }
      <!-- File upload -->
      <!-- Optional field -->
      <div class="flex flex-col gap-y-10">
        <label class="text-base font-bold text-gray-dark required-label" for="contribution-uploads">Please upload any
        required documents below</label>
        @if (parentForm.value) {
          <app-file-dropper (fileChangeEvent)="uploadFiles($event)" [value]="parentForm.value.files"></app-file-dropper>
        }
      </div>
    </section>
  </form>
}