import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { InvestmentRequestFormService } from 'src/app/portal/features/account-request-workflows/services/investment-request-form.service';
import { Dollar, Share } from '../../../../constants/allocation-amount.constant';
import { CustomHoldingsBuyActions, CustomHoldingsSellActions } from '../../../../constants/custom-holdings.constant';
import { Buy, Sell } from '../../../../constants/trade-action.constants';
import { RequestTypeEnum } from '../../../../enums/account-update.enum';
import { AllocationCustomHoldingsFormGroup, AllocationCustomHoldingSleeveFormArray, AllocationCustomHoldingSleeveFormGroup } from '../../../../models/allocation-form.models';
import { TradeInstructionOptionModel } from '../../../../models/workflow.models';
import { CustomCurrencyMaskConfig } from 'src/app/shared/config/currency-mask.config';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';

@Component({
  selector: 'app-custom-holdings-allocation',
  templateUrl: './custom-holdings-allocation.component.html',
})
export class CustomHoldingsAllocationComponent implements OnInit, OnDestroy {
  @Input() parentForm?: AllocationCustomHoldingsFormGroup;
  @Input() newCustomHolding?: AllocationCustomHoldingSleeveFormGroup;
  @Input() customHoldingsAmount?:number;
  @Input() requestType?: RequestTypeEnum;
  @Input() investmentRequestFormService?: InvestmentRequestFormService;
  requestTypeEnum = RequestTypeEnum;
  customHoldingsBuyActions = CustomHoldingsBuyActions;
  customHoldingsSellActions = CustomHoldingsSellActions;
  allTradeActions = this.customHoldingsBuyActions.concat(this.customHoldingsSellActions);
  allocationActionOptions: TradeInstructionOptionModel[] = [];
  showBuyingOptions = true;
  customAllocationFormArray?: AllocationCustomHoldingSleeveFormArray;
  Buy = Buy;
  Sell = Sell;
  Dollar = Dollar;
  Share = Share;
  customHoldingShareAmountMaskOptions: NgxCurrencyConfig = {
    ...CustomCurrencyMaskConfig, ...{
      allowNegative: false,
      prefix: '',
      suffix: '',
      precision: 0,
      min: 0,
      max: 9999999999,
      inputMode: NgxCurrencyInputMode.Natural
    }
  };

  constructor (
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.customAllocationFormArray = this.parentForm?.controls.allocation;
    this.newCustomHolding?.controls.amount.disable();
  }

  ngOnDestroy(): void {
    this.addNewCustomAllocation();
  }

  addNewCustomAllocation(): void {
    if (this.newCustomHolding?.valid) {
      this.investmentRequestFormService?.addNewCustomAllocation(this.newCustomHolding);
      this.newCustomHolding.reset();
      this.newCustomHolding.patchValue({type: this.requestType === RequestTypeEnum.contribution ? 'Buy' : this.requestType === RequestTypeEnum.distribution ? 'Sell' : ''});
    }
  }

  removeCustomAllocation(index: number): void {
    this.investmentRequestFormService?.removeCustomAllocation(index);
  }

  getTradeActionOptions(item: AllocationCustomHoldingSleeveFormGroup): TradeInstructionOptionModel[]{
    if (item.value.type) {
      item.controls.action.enable();
      return this.allTradeActions.filter(x => x.tradeType === item.value.type);
    } else {
      item.controls.action.disable();
      return [];
    }
  }

  updateCustomHoldingValidation(item: AllocationCustomHoldingSleeveFormGroup): void {
    if ((item.controls.action.value as TradeInstructionOptionModel).isSellAll) {
      item.patchValue({amount: null});
      item.controls.amount.clearValidators();
      item.controls.amount.disable();
    } else {
      item.controls.amount.addValidators([Validators.required]);
      item.controls.amount.enable();
    }
  }
}
