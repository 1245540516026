<section class="flex w-full h-full p-20 md:p-30 bg-top bg-no-repeat bg-cover"
  style="background: url(./assets/images/wave-bg.svg)">
  <div class="flex flex-col w-full mx-auto">
    <div class="flex flex-col flex-grow overflow-hidden card">
      <div class="flex items-center justify-between p-20 border-b border-solid md:p-30 border-gray-lighter">
        <div class="flex flex-row">
          <h2 class="flex items-center mr-70 text-lg font-bold"><span>Accounts</span></h2>
          <div class="relative flex w-full input pl-30 md:w-300 focus:ring-2 focus:ring-blue-light">
            <mat-icon svgIcon="magnify" class="absolute left-0 ml-5 transform -translate-y-1/2 top-1/2 fill-gray h-18">
            </mat-icon>
            <input class="w-full ml-5 font-medium text-sm outline-none appearance-none text-gray-dark" type="text"
              name="search" aria-label="Search accounts" placeholder="Search Accounts..." [(ngModel)]="searchTerm"
              (appDebouncedModelChanged)="filterAccounts()" />
            @if (searchTerm.length > 0) {
              <mat-icon (click)="searchTerm=''; filterAccounts()" svgIcon="close"
                class="absolute right-0 ml-5 transform -translate-y-1/2 top-1/2 fill-gray h-15 cursor-pointer">
              </mat-icon>
            }
          </div>
        </div>
        <div class="flex flex-row gap-x-20">
          <ng-container *appShowIfOne="perm.ViewOrionElementProposals">
            <button mat-button class="px-10 button is-primary is-inverted"
              (click)="openOrionAgentSelectModal('ap_proposals')">
              <span class="flex items-center justify-center ">
                <span class="mr-5 material-icons mi-18">add</span>
                <span>New Account</span>
              </span>
            </button>
          </ng-container>
          <ng-container *appHideIfOne="perm.ViewOrionElementProposals">
            <ng-container *appShowIfOne="[perm.SubmitNewPaperAccount]">
              <button mat-button [matMenuTriggerFor]="newAccountMenu" class="px-10 button is-primary is-inverted">
                <span class="flex items-center justify-center ">
                  <span class="mr-5 material-icons mi-18">add</span>
                  <span>New Account</span>
                </span>
              </button>
              <mat-menu #newAccountMenu="matMenu">
                <div class="flex flex-col items-start gap-y-10">
                  <ng-container *appShowIfOne="perm.ViewSchwabAdvisorCenterSso">
                    <a mat-menu-item
                      class="flex items-center justify-start button is-primary is-lg is-inverted text-gray-darker"
                      aria-label="Open Schwab Account Opening" href="api/sso/schwab?destination=F_APPS" target="_blank">
                      <span>Launch Schwab Account Opening</span>
                    </a>
                  </ng-container>
                  <ng-container *appShowIfAll="perm.SubmitNewPaperAccount">
                    <button class="button is-primary is-lg is-inverted text-gray-darker" mat-menu-item
                      aria-label="Open paper application" (click)="openPaperAppModal()">
                      <span>Paper Application</span>
                    </button>
                  </ng-container>
                </div>
              </mat-menu>
            </ng-container>
          </ng-container>
          <button mat-button class="px-10 button is-primary is-inverted" aria-label="Toggle Filters"
            (click)="accountsFilter.toggle()">
            <span class="flex items-center justify-center ">
              <mat-icon svgIcon="filter" class="mr-5 mi-18"></mat-icon>
              <span>Filters</span>
            </span>

          </button>
        </div>
      </div>
      <mat-sidenav-container autosize="true" class="flex h-full overflow-hidden bg-white text-gray-dark">
        <mat-sidenav-content class="flex flex-col flex-grow overflow-hidden h-full">
          <app-accounts class="pb-20 md:pb-30 w-full h-full overflow-auto flex-grow"
          [accounts]="filteredAccounts"></app-accounts>
        </mat-sidenav-content>
        <mat-sidenav #accountsFilter [opened]="sidenavConfig.IsOpened" [mode]="sidenavConfig.Mode"
          class="flex flex-col w-220 gap-y-10" position="end">
          <section class="flex flex-col flex-none mx-10 mt-20">
            <label class="text-sm text-gray">Advisors</label>
            @if (!isArcAdvisor) {
              @for (advisorCheckbox of tableFilter.Advisors; track advisorCheckbox) {
                <mat-checkbox class="flex text-sm"
                  [attr.aria-label]="advisorCheckbox.Name + ' checkbox'" [(ngModel)]="advisorCheckbox.Checked"
                  (ngModelChange)="filterAccounts()" [disabled]="isLoading">
                  <span class="whitespace-normal">
                    {{advisorCheckbox.Name}}
                  </span>
                </mat-checkbox>
              }
            } @else {
              <div class="relative flex w-full input pl-30 md:w-250 focus:ring-2 focus:ring-blue-light">
                <mat-icon svgIcon="magnify"
                  class="absolute left-0 ml-5 transform -translate-y-1/2 top-1/2 fill-gray h-18">
                </mat-icon>
                <input class="w-full ml-5 font-medium outline-none appearance-none text-gray-dark text-sm" type="text"
                  name="search" aria-label="Search advisor" placeholder="Filter..." [(ngModel)]="advisorSearchTerm"
                  (appDebouncedModelChanged)="filterAdvisorList()" />
                @if (advisorSearchTerm.length > 0) {
                  <mat-icon (click)="advisorSearchTerm=''; filterAdvisorList()"
                    svgIcon="close"
                    class="absolute right-0 ml-5 transform -translate-y-1/2 top-1/2 fill-gray h-15 cursor-pointer">
                  </mat-icon>
                }
              </div>
              <div class="h-360 overflow-y-scroll">
                <mat-radio-group class="flex flex-col flex-none gap-y-10" aria-label="Advisors list"
                  [(ngModel)]="selectedRadioAdvisorId" [disabled]="isLoading">
                  @for (advisorRadio of filteredAdvisors; track advisorRadio; let index = $index) {
                    <mat-radio-button class="flex"
                      [value]="advisorRadio.Value"
                      [attr.aria-label]="advisorRadio.Name + ' radio button'" [disabled]="isLoading"
                      (click)="radioAdvisorChecked(advisorRadio.Value)">
                      <span class="whitespace-normal">
                        {{advisorRadio.Name}}
                      </span>
                    </mat-radio-button>
                  }
                </mat-radio-group>
              </div>
            }
          </section>
          <!--<section class="flex flex-col flex-none mx-20 mt-20 gap-y-10">
          <label class="text-sm text-gray mt-10">Status</label>
          <mat-button-toggle-group class="button-toggle-group mb-20" [(ngModel)]="tableFilter.Status"
            name="accountStatus" aria-label="Account Status" [disabled]="isLoading">
            <mat-button-toggle class="w-1/3 min-w-72" value="All" (change)="filterAccounts()">All</mat-button-toggle>
            <mat-button-toggle class="w-1/3 min-w-72" value="Open" (change)="filterAccounts()">Open</mat-button-toggle>
            <mat-button-toggle class="w-1/3 min-w-72" value="Closed" (change)="filterAccounts()">Closed</mat-button-toggle>
          </mat-button-toggle-group>
          </section>-->
          @if (hasInsuranceAccounts) {
            <section class="flex flex-col flex-none mx-10 mt-20">
              <label class="text-sm text-gray">Account Type</label>
              @for (type of tableFilter.Types; track type) {
                <mat-checkbox class="flex"
                  [attr.aria-label]="type.Name + ' type checkbox'" [(ngModel)]="type.Checked"
                  (ngModelChange)="filterAccounts()" [disabled]="isLoading">
                  {{type.Name}}
                </mat-checkbox>
              }
            </section>
          }
        </mat-sidenav>
      </mat-sidenav-container>
    </div>
  </div>
</section>