import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { API_BASE_URL } from './generated/client';

@NgModule({ declarations: [], imports: [], providers: [
  {
    provide: API_BASE_URL,
    useFactory: (): string => '/api',
  },
  provideHttpClient(withInterceptorsFromDi())
] })
export class ClientsModule { }
